/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Papa from "papaparse";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";

import ImportModal from "./import";

import SchoolSelector from "../../modules/SchoolSelector";
import StudentNameToDatabase from "../../modules/StudentNameToDatabase";
import UndeleteStudent from "../../modules/UndeleteStudent";
import StudentSyncDataByTraineeID from "../../modules/StudentSyncDataByTraineeID";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormSelect2 from "../../components/FormSelect2";
import FormDatePicker from "../../components/FormDatePicker";
import FormDateOfBirth from "../../components/FormDateOfBirth";
import FormPhoneField from "../../components/FormPhoneField";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";

import {
  InfoButton,
  SuccessButton,
  GreyButton,
  ButtonGroup,
  AInfoLink,
  ASuccessLink,
  InverseButton,
  ErrorButton,
  AInverseLink,
} from "../../styles/button";
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";
import { isAdmin, hasAccessRights, getUserRegionFilter } from "../../helpers/auth";
import { doSchemaErrorCheck } from "../../helpers/schemas";
import { getStudentEnrollmentStatus, checkForDuplication } from "../../helpers/trainees";
import { getAPIErrorMessage } from "../../helpers/action";
import {
  getCustomFields,
  isDefaultField,
  isUniqueField,
  getFieldType,
  getCustomFieldValue,
  getCustomFieldOptions,
  getCustomField,
} from "../../helpers/registration_form";

import { editTrainee, deleteTrainee, massEditTrainees, undeleteTrainee } from "../../actions/trainees";
import { fb_getStudentUIDByEmail } from "../../actions/database_students/fb";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, DEV_MODE } from "../../constants";

const TextareaWrapper = styled.div`
  textarea {
    width: 100%;
    max-width: 150px;
    font-size: 11px;
    padding: 2px;
    overflow: scroll-y;
  }
`;

const StudentProfileButton = ({ open, email }) => {
  const [loaded, setLoaded] = useState(false);
  const [uid, setUID] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (open && email) {
      fb_getStudentUIDByEmail(email)
        .then((userRecord) => {
          if (mounted) {
            setUID(userRecord && userRecord.uid && !_isEmpty(userRecord.uid) ? userRecord.uid : false);
            setLoaded(true);
          }
        })
        .catch((error) => {
          // triggerErrorAlert(getAPIErrorMessage(error));
          setLoaded(true);
        });
    }
    return () => (mounted = false);
  }, [open, email]);

  return loaded ? (
    uid && !_isEmpty(uid) ? (
      <ASuccessLink href={"/student_database/" + uid} target="_blank" size="small" style={{ padding: "5px 15px" }}>
        <i className="fa fa-search"></i>View Student Profile
      </ASuccessLink>
    ) : (
      <Typography variant="body1" style={{ fontSize: "12px" }}>
        No student profile found
      </Typography>
    )
  ) : (
    <CircularProgress size={20} />
  );
};

class StudentsTable extends React.Component {
  state = {
    checked: [],
    allCheck: false,
    searchterms: "",
    filterBy: "all",
    filterStartDate: moment().utcOffset(8).set({ year: 2021, month: 0, date: 1 }).startOf("date").valueOf(),
    filterEndDate: moment().utcOffset(8).add(6, "months").endOf("date").valueOf(),
    sortBy: "date-desc",
    perPage: 20,
    page: 1,
    openViewModal: false,
    modalData: false,
    openDeleteModal: false,
    deleteModal: false,
    openImportModal: false,
    openEditSchoolModal: false,
    massEditSchool: false,
    randNum: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  doCSVDownload = (results) => {
    let csv = Papa.unparse(results, { delimiter: "," });
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); //new way
    let uri = window.URL.createObjectURL(blob);
    let link = document.createElement("a");

    link.setAttribute("href", uri);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "export_registration.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleCSVDownload = () => {
    const { schedule, trainees, regions, registration_forms } = this.props;
    const { checked } = this.state;
    if (checked && isArrayExists(checked)) {
      const nonDefaultfields = getCustomFields(schedule, registration_forms, "non_default");
      let csvData = {
        fields: [
          "Partner",
          "Class Name",
          "Registration Date",
          "Email",
          "Name",
          "Gender",
          "Age",
          "School",
          "Medical Condition",
          "Class Status",
          "Parent Name",
          "Parent Email",
          "Parent Mobile No",
          "Parent City",
        ],
        data: [],
      };

      _forEach(nonDefaultfields, (field) => {
        csvData.fields.push(field.label || "");
      });

      _forEach(checked, (item) => {
        let trainee = _find(trainees, { id: item.id });
        if (trainee && !_isEmpty(trainee)) {
          let row = [];
          const status = trainee ? getStudentEnrollmentStatus(trainee) : "";
          row.push(getSelectedValue(regions, trainee.region, "id", "label", "-"));
          row.push((schedule && schedule.class_name) || "");
          row.push(trainee && trainee.created_on ? getMomentTime(trainee.created_on, "DD MMM YYYY") : "");
          row.push((trainee && trainee.email) || "");
          row.push((trainee && trainee.name) || "");
          row.push(trainee && trainee.gender && trainee.gender === "male" ? "Male" : "Female");
          row.push(
            trainee && trainee.date_of_birth_string && !_isEmpty(trainee.date_of_birth_string) ? getAge(trainee.date_of_birth_string) : ""
          );
          row.push((trainee && trainee.org) || "");
          row.push((trainee && trainee.medical_condition) || "");

          if (status && status === "inactivate") {
            row.push("Inactive");
          } else {
            row.push(status);
          }

          row.push((trainee && trainee.parent_name) || "");
          row.push((trainee && trainee.parent_email) || "");
          row.push((trainee && trainee.parent_phone) || "");
          row.push((trainee && trainee.parent_city) || "");

          _forEach(nonDefaultfields, (field) => {
            row.push(getCustomFieldValue(field, trainee));
          });

          csvData.data.push(row);
        } // end - trainee
      });
      this.doCSVDownload(csvData);
    } else {
      triggerErrorAlert("Please select at least one student");
    } // end - checked
  };

  triggerTraineeDelete = () => {
    const { authData, currentSchedule, trainees } = this.props;
    const { modalData } = this.state;
    // perform delete
    // make sure can't delete trainee if already in a team
    const selected = _find(trainees, { id: modalData.id });
    if (selected && selected.teams && isArrayExists(selected.teams)) {
      triggerErrorAlert("Please remove this student from the team before deleting");
    } else {
      this.setState({ openDeleteModal: true, deleteModal: cloneCollections(modalData) });
    }
  };

  handleMassSchoolUpdate = (newValue) => {
    const { trainees, currentSchedule } = this.props;
    const { checked } = this.state;
    if (checked && isArrayExists(checked)) {
      let list = [];
      _forEach(checked, (item) => {
        let trainee = _find(trainees, { id: item.id });
        if (trainee && !_isEmpty(trainee)) {
          let item = cloneCollections(trainee);
          item.org = newValue;
          list.push(item);
        }
      });
      this.props.dispatch(massEditTrainees(list, { schedule_id: currentSchedule }));
    } else {
      triggerErrorAlert("Please select at least one student");
    }
    this.setState({ openEditSchoolModal: false, massEditSchool: false });
  };

  handleTraineeDelete = () => {
    const { authData, currentSchedule, trainees } = this.props;
    const { deleteModal } = this.state;
    // perform delete
    // make sure can't delete trainee if already in a team
    const selected = _find(trainees, { id: deleteModal.id });
    if (selected && selected.teams && isArrayExists(selected.teams)) {
      triggerErrorAlert("Please remove this student from the team before deleting");
    } else {
      this.props.dispatch(deleteTrainee(deleteModal.id, { schedule_id: currentSchedule }));
    }
  };

  handleTraineeUpdate = () => {
    const { currentSchedule } = this.props;
    const { modalData } = this.state;
    let formData = {},
      error = false;

    // do error check
    traineeSchema.forEach((schema) => {
      formData[schema.id] = modalData && modalData[schema.id] ? cloneCollections(modalData[schema.id]) : schema.default;
      if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
        error = 'Please fill out "' + schema.label + '" field';
      }
    });

    if (error) {
      triggerErrorAlert(error);
    } else {
      this.props.dispatch(editTrainee(formData, { schedule_id: currentSchedule || false }));
    } // end - error
  };

  handleFormUpdate = (newValue, key, subkey, subVal) => {
    const { modalData } = this.state;
    let newData = modalData ? cloneCollections(modalData) : {};
    switch (key) {
      // case 'course_type':
      //     let selectedType = ( course_type && isArrayExists( course_type ) ? _find( course_type, { id: newValue } ) : false );
      //     newData[key] = newValue;
      //     newData[key + '_label'] = ( selectedType && selectedType.label ? selectedType.label : '' );
      //     break;
      case "date_of_birth_string":
        newData[key] = newValue;
        newData["date_of_birth"] = subVal;
        newData["age"] = getAge(newValue); // add age
        break;
      default:
        if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
          // use subVal as the new value
          newData[key] = subVal;
        } else {
          // otherwise - just update normally
          newData[key] = newValue;
        }
        break;
    } // end - key
    this.setState({ modalData: newData });
  };

  handleCustomFieldUpdate = (field) => (newValue) => {
    const { modalData } = this.state;
    let newData = modalData ? cloneCollections(modalData) : {},
      index = newData && newData.custom_fields ? newData.custom_fields.findIndex((item) => item.id === field.id) : -1;
    if (index > -1) {
      newData.custom_fields[index].value = newValue;
    } else {
      newData.custom_fields.push({
        id: field.id,
        value: newValue,
        type: field.type,
      });
    }

    this.setState({ modalData: newData });
  };

  getActions = () => {
    const { authData } = this.props;
    let actions = [];

    actions.push({
      id: "masseditschool",
      label: "Mass Edit School",
      icon: "fa-edit",
      onClick: () => {
        const { checked } = this.state;
        if (checked && isArrayExists(checked)) {
          this.setState({ openEditSchoolModal: true, massEditSchool: "" });
        } else {
          triggerErrorAlert("Please select at least one student");
        }
      },
    });

    actions.push({
      id: "bulkupload",
      label: "Bulk Upload",
      icon: "fa-cloud-upload",
      onClick: () => this.setState({ openImportModal: true }),
    });

    actions.push({
      id: "downloadcsv",
      label: "Download Selected as CSV",
      icon: "fa-cloud-download",
      onClick: this.handleCSVDownload,
    });

    return actions;
  };

  reorganizeData() {
    const { searchterms, filterBy, sortBy, perPage, page, filterEndDate, filterStartDate } = this.state;
    const { trainees } = this.props;
    let items = trainees ? cloneCollections(trainees) : [],
      total = _size(items);

    // do search
    if (searchterms && !_isEmpty(searchterms)) {
      items = doArraySearch(items, searchterms, ["name", "email", "org"]);
      total = _size(items);
    } // end - searchterms

    // do filter
    if (filterBy && !_isEmpty(filterBy) && filterBy !== "all") {
      if (filterBy === "duplicates") {
        items = _filter(items, (i) => checkForDuplication(trainees, i.email));
        total = _size(items);
      } else {
        items = _filter(items, { enrollment_status: filterBy });
        total = _size(items);
      }
    } // end - filterBy

    // filter by date
    if (filterStartDate && filterEndDate) {
      items = _filter(items, (i) => {
        let date = moment(i.created_on).utcOffset(8).format("YYYY-MM-DD");
        return moment(date).utcOffset(8).isBetween(filterStartDate, filterEndDate);
      });
      total = _size(items);
    } // end - filter date

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
      switch (sortBy) {
        case "date-desc":
          items = _sortBy(items, ["created_on"]);
          items = _reverse(items);
          break;
        case "date-asc":
          items = _sortBy(items, ["created_on"]);
          break;
        case "name-desc":
          items = _sortBy(items, ["name"]);
          items = _reverse(items);
          break;
        case "name-asc":
          items = _sortBy(items, ["name"]);
          break;
        case "email-desc":
          items = _sortBy(items, ["email"]);
          items = _reverse(items);
          break;
        case "email-asc":
          items = _sortBy(items, ["email"]);
          break;
        case "org-desc":
          items = _sortBy(items, ["org"]);
          items = _reverse(items);
          break;
        case "org-asc":
          items = _sortBy(items, ["org"]);
          break;
      }
    } // end - sortBy

    // do pagination
    items = doPagination(items, perPage, page);

    return { items, total };
  }

  renderMedicalConditionField = () => {
    const { schedule, registration_forms } = this.props;
    const { modalData } = this.state;
    const fields = getCustomFields(schedule, registration_forms, "all");
    const mediField = getCustomField(fields, "medical_condition");
    return (
      fields &&
      mediField && (
        <FormInput
          label={((mediField && mediField.label) || "") + (mediField && mediField.required === "yes" ? " *" : "")}
          name="medical_condition"
          value={modalData.medical_condition || ""}
          onChange={this.handleFormUpdate}
        />
      )
    );
  };

  renderSchoolField = () => {
    const { schedule, registration_forms } = this.props;
    const { modalData } = this.state;
    const fields = getCustomFields(schedule, registration_forms, "all");
    const schoolField = getCustomField(fields, "org");
    const stateField = getCustomField(fields, "state");
    return fields && schoolField ? (
      <SchoolSelector
        predefinedState={stateField ? getCustomFieldValue(stateField, modalData) : null}
        value={modalData.org || ""}
        field={schoolField}
        onChange={this.handleFormUpdate}
      />
    ) : (
      <FormInput label="School" name="org" value={modalData.org || ""} onChange={this.handleFormUpdate} />
    );
  };

  renderStudentForm = () => {
    const { regions, registration_forms, schedule, races, districts } = this.props;
    const { modalData, openViewModal } = this.state;
    const customFields = getCustomFields(schedule, registration_forms, "non_default");
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} align="right">
            <StudentProfileButton
              open={openViewModal}
              email={modalData && modalData.email && !_isEmpty(modalData.email) ? modalData.email : false}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              label="Partner Name *"
              name="region"
              value={modalData.region || ""}
              options={getSelectOptions({
                list: regions || false,
                options: [],
                keys: { value: "id", label: "label", disabled: "status" },
                sortBy: "label",
              })}
              disabled={true}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Name *" name="name" value={modalData.name || ""} onChange={this.handleFormUpdate} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              label="Email *"
              name="email"
              type="email"
              disabled={
                getStudentEnrollmentStatus(modalData) === "registered" || getStudentEnrollmentStatus(modalData) === "trial" ? false : true
              }
              value={modalData.email || ""}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              label="Gender *"
              name="gender"
              value={modalData.gender || ""}
              options={GENDER_OPTIONS}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateOfBirth
              label="Date of Birth *"
              name="date_of_birth_string"
              value={modalData.date_of_birth_string || ""}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.renderSchoolField()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.renderMedicalConditionField()}
          </Grid>
          {customFields && isArrayExists(customFields) ? (
            <>
              <Grid item xs={12}>
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
              </Grid>
              {customFields.map((field, index) => {
                switch (field.type) {
                  case "input":
                    return (
                      <Grid item xs={12} sm={6} key={field.id}>
                        <FormInput
                          label={field.label || ""}
                          value={getCustomFieldValue(field, modalData)}
                          onChange={this.handleCustomFieldUpdate(field)}
                        />
                      </Grid>
                    );
                  case "textarea":
                    return (
                      <Grid item xs={12} sm={6} key={field.id}>
                        <FormInput
                          label={field.label || ""}
                          multiline={true}
                          rows={3}
                          value={getCustomFieldValue(field, modalData)}
                          onChange={this.handleCustomFieldUpdate(field)}
                        />
                      </Grid>
                    );
                  case "state":
                  case "race":
                  case "district":
                  case "select":
                  case "iam":
                    return (
                      <Grid item xs={12} sm={6} key={field.id}>
                        <div style={{ paddingTop: "12px" }}>
                          <FormSelect
                            label={(field.label || "") + (field.required && field.required === "yes" ? " *" : "")}
                            value={getCustomFieldValue(field, modalData)}
                            options={getCustomFieldOptions(
                              field,
                              {
                                races,
                                districts,
                                selectedState:
                                  field.type === "district" ? getCustomFieldValue(getCustomField(customFields, "state"), modalData) : null,
                              },
                              true
                            )}
                            onChange={this.handleCustomFieldUpdate(field)}
                          />
                        </div>
                      </Grid>
                    );
                  case "radio":
                    return (
                      <Grid item xs={12} sm={6} key={field.id}>
                        <div style={{ padding: "10px" }}>
                          <FormRadio
                            label={(field.label || "") + (field.required && field.required === "yes" ? " *" : "")}
                            value={getCustomFieldValue(field, modalData)}
                            options={getCustomFieldOptions(field, {}, false)}
                            onChange={this.handleCustomFieldUpdate(field)}
                          />
                        </div>
                      </Grid>
                    );
                }
              })}
            </>
          ) : null}
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Parent Name" name="parent_name" value={modalData.parent_name || ""} onChange={this.handleFormUpdate} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} sm={10}>
                <FormInput
                  label="Parent Email"
                  name="parent_email"
                  type="email"
                  value={modalData.parent_email || ""}
                  onChange={this.handleFormUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={2} align="right">
                <AInverseLink
                  target="_blank"
                  href={`mailto:${modalData.parent_email || ""}`}
                  style={{ padding: "10px 0px 10px 5px", marginTop: "10px" }}
                >
                  <i className="fa fa-envelope" style={{ fontSize: "30px" }}></i>
                </AInverseLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} sm={10}>
                <FormPhoneField
                  label="Parent Mobile No."
                  name="parent_phone"
                  value={modalData.parent_phone || ""}
                  onChange={this.handleFormUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={2} align="right">
                <AInverseLink
                  target="_blank"
                  href={`https://api.whatsapp.com/send/?phone=${
                    modalData.parent_phone && !_isEmpty(modalData.parent_phone) ? modalData.parent_phone.replace("+", "") : ""
                  }`}
                  style={{ padding: "10px 0px 10px 5px", marginTop: "15px" }}
                >
                  <i className="fa fa-whatsapp" style={{ fontSize: "30px" }}></i>
                </AInverseLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput label="Parent City" name="parent_city" value={modalData.parent_city || ""} onChange={this.handleFormUpdate} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              multiline={true}
              rows={3}
              label="Registration Remarks"
              name="remarks"
              value={modalData.remarks || ""}
              onChange={this.handleFormUpdate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ paddingTop: "20px" }}>
              <ErrorButton
                disabled={
                  modalData &&
                  modalData.enrollment_status &&
                  (modalData.enrollment_status === "registered" || modalData.enrollment_status === "trial")
                    ? null
                    : "yes"
                }
                onClick={this.triggerTraineeDelete}
                style={{ padding: "5px 15px" }}
              >
                <i className="fa fa-trash"></i>Remove This Student from this Class
              </ErrorButton>
              <span style={{ paddingLeft: "8px" }}>
                <ToolTipInfo
                  content={
                    <>
                      THIS ACTION CANNOT BE REVERSED.
                      <br />
                      <br />
                      Remove this student from this class so that he/she will not appear on the later modules. This is disabled if the
                      student’s status is CONFIRMED.
                    </>
                  }
                />
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  renderEnrollmentStatus = (type, item) => {
    if (item[type] && !_isEmpty(item[type])) {
      switch (item[type]) {
        case "registered":
        case "trial":
          return (
            <WrapWord style={{ fontSize: "12px", fontWeight: "700", textTransform: "uppercase", padding: "4px 8px" }}>
              {item[type]}
            </WrapWord>
          );
        case "confirmed":
          return <SuccessTag>{item[type]}</SuccessTag>;
        case "active":
          return <InfoTag>{item[type]}</InfoTag>;
        case "inactivate":
          return <GreyTag>Inactive</GreyTag>;
        case "conclude":
          return <SuccessTag>Concluded</SuccessTag>;
        case "delete":
          return <ErrorTag>Deleted</ErrorTag>;
      } // end - item[type]
    } // end - item[type]
  };

  renderCell = (type) => (item) => {
    const { regions, schedules, currentSchedule, trainees } = this.props;
    switch (type) {
      case "region":
        return getSelectedValue(regions, item[type], "id", "label", "-");
      case "age":
        return item.date_of_birth_string && !_isEmpty(item.date_of_birth_string) ? getAge(item.date_of_birth_string) : "-";
      case "created_on":
        return <WrapWord>{item[type] ? getMomentTime(item[type], "DD MMM YYYY") : "---"}</WrapWord>;
      case "email":
        return (
          <>
            <WrapWord>{item[type] || "-"}</WrapWord>
            {checkForDuplication(trainees, item[type]) ? (
              <div>
                <AmberTag showBorder="yes" style={{ marginTop: "10px" }}>
                  <ToolTipInfo
                    icon_color="#ffa000"
                    content={
                      <>
                        DUPLICATED
                        <br />
                        <br />
                        There are more than 1 registrations with the same email address. Please delete the duplicate(s) to proceed.
                      </>
                    }
                  />
                  DUPLICATED
                </AmberTag>
              </div>
            ) : null}
            {item[type] &&
            item.bounced_emails &&
            isArrayExists(item.bounced_emails) &&
            _find(item.bounced_emails, (e) => e === item[type]) ? (
              <div>
                <AmberTag showBorder="yes" style={{ marginTop: "10px" }}>
                  <ToolTipInfo
                    icon_color="#ffa000"
                    content={
                      <>
                        BOUNCED
                        <br />
                        <br />
                        Email got bounced when sending registration receipt. This email may be incorrect. Please verify with the student.
                      </>
                    }
                  />
                  BOUNCED
                </AmberTag>
              </div>
            ) : null}
          </>
        );
      case "class_name":
        let schedule = schedules ? _find(schedules, { id: currentSchedule }) : false;
        return <WrapWord>{schedule && schedule.class_name ? schedule.class_name : ""}</WrapWord>;
      case "parent_details":
        return (item.parent_name && !_isEmpty(item.parent_name)) ||
          (item.parent_email && !_isEmpty(item.parent_email)) ||
          (item.parent_phone && !_isEmpty(item.parent_phone)) ||
          (item.parent_city && !_isEmpty(item.parent_city)) ? (
          <div style={{ width: "150px" }}>
            <strong>Name:</strong> <WrapWord>{item.parent_name && !_isEmpty(item.parent_name) ? item.parent_name : "-"}</WrapWord>
            <br />
            <strong>Email:</strong> <WrapWord>{item.parent_email && !_isEmpty(item.parent_email) ? item.parent_email : "-"}</WrapWord>
            <br />
            <strong>Phone:</strong> <WrapWord>{item.parent_phone && !_isEmpty(item.parent_phone) ? item.parent_phone : "-"}</WrapWord>
            <br />
            <strong>City:</strong> <WrapWord>{item.parent_city && !_isEmpty(item.parent_city) ? item.parent_city : "-"}</WrapWord>
          </div>
        ) : (
          "-"
        );
      case "remarks":
        return (
          <div className="disabled-wrapper">
            <TextareaWrapper>
              <textarea rows={3} disabled="disabled" value={item.remarks && !_isEmpty(item.remarks) ? item.remarks : "-"} />
            </TextareaWrapper>
          </div>
        );
    }
  };

  renderTableActions = () => {
    const { authData } = this.props;
    const { sortBy, perPage, filterBy, searchterms, checked, filterStartDate, filterEndDate } = this.state;
    return (
      <TableBar
        sortBy={sortBy}
        // show={['entries','sortby']}
        perPage={perPage}
        // filterBy={filterBy}
        searchterms={searchterms}
        sortByOptions={[
          { value: "date-desc", label: "Registration Date (Recent First)" },
          { value: "date-asc", label: "Registration Date (Oldest First)" },
          { value: "name-asc", label: "Name ( A - Z)" },
          { value: "name-desc", label: "Name ( Z - A )" },
          { value: "email-asc", label: "Email ( A - Z)" },
          { value: "email-desc", label: "Email ( Z - A )" },
          { value: "org-asc", label: "School ( A - Z)" },
          { value: "org-desc", label: "School ( Z - A )" },
        ]}
        leftWidth="35%"
        rightWidth="65%"
        leftButtons={
          checked && isArrayExists(checked)
            ? [
                <div key="check_option" style={{ marginLeft: "15px", paddingTop: "5px" }}>
                  <GreyButton
                    style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                    onClick={() => this.setState({ checked: [], allCheck: false })}
                  >
                    <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                    {_size(checked) + " selected"}
                  </GreyButton>
                </div>,
              ]
            : null
        }
        rightButtons={[
          <ButtonActions
            key="actions"
            label="Actions"
            menuContainerStyle={{ width: "250px" }}
            style={{ marginRight: "5px" }}
            actions={this.getActions()}
          />,
        ]}
        secondLineOptions={[
          <div style={{ width: "250px", paddingTop: "14px" }}>
            <FormSelect2
              label="Filter By"
              options={getSelectOptions({
                list: _filter(ENROLLMENT_STATUS_OPTIONS, { confirmation: true }),
                options: [
                  { value: "all", label: "All Status" },
                  { value: "duplicates", label: "Email Duplications" },
                  { value: "delete", label: "Deleted" },
                ],
                keys: { value: "value", label: "label" },
              })}
              value={filterBy || false}
              isClearable={true}
              isSearchable={true}
              onChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
            />
          </div>,
          <div style={{ width: "350px" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormDatePicker
                  InputAdornmentProps={{ disabled: true }}
                  value={filterStartDate}
                  label="From"
                  name="start_date"
                  onChange={(newValue) => {
                    if (newValue < filterEndDate) {
                      this.setState({ filterStartDate: newValue, page: 1 });
                    } else {
                      // triggerErrorAlert("Start Date cannot be after End Date");
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormDatePicker
                  InputAdornmentProps={{ disabled: true }}
                  value={filterEndDate}
                  label="Until"
                  name="end_date"
                  onChange={(newValue) => {
                    if (newValue > filterStartDate) {
                      this.setState({ filterEndDate: newValue, page: 1 });
                    } else {
                      // triggerErrorAlert("End Date cannot be before Start Date");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>,
        ]}
        // filterByOptions={getSelectOptions({ list: _filter( ENROLLMENT_STATUS_OPTIONS, { confirmation: true } ), options: [{ value: 'all', label: 'All Status' },{ value: 'duplicates', label: 'Email Duplications' }], keys: { value: 'value', label: 'label' } })}
        onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
        onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
        onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
        // onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
        style={{ marginBottom: "20px" }}
      />
    );
  };

  renderPagination = (totalCount) => {
    const { perPage, page } = this.state;
    return (
      <Pagination
        total={totalCount}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        // entriesLabel="(Total Registration For This Class)"
        onPageChange={(newPage) => this.setState({ page: newPage })}
      />
    );
  };

  render() {
    const { authData, users, schedule, trainees, registration_forms } = this.props;
    const { openDeleteModal, deleteModal, openViewModal, openImportModal, allCheck, checked, openEditSchoolModal, massEditSchool } =
      this.state;
    const { items, total } = this.reorganizeData();
    return (
      <div>
        <ModalView
          open={openViewModal}
          title="Edit Student Details"
          maxWidth="lg"
          onClose={() => this.setState({ openViewModal: false, modalData: false })}
          disableBackdrop={true}
          actionLabel="Update"
          doAction={this.handleTraineeUpdate}
          contents={this.renderStudentForm()}
        />

        <SchoolSelector
          value={(massEditSchool && massEditSchool.state) || ""}
          showInputField={false}
          forceOpenModal={openEditSchoolModal}
          onClose={() => this.setState({ openEditSchoolModal: false, massEditSchool: false, checked: [], allCheck: false })}
          onChange={this.handleMassSchoolUpdate}
        />

        {authData && hasAccessRights(authData, ["crm"]) ? (
          <ModelDelete
            open={openDeleteModal}
            title={
              deleteModal && deleteModal.name
                ? `Are you sure you want to remove this student ( ${deleteModal.name} ) from this class?`
                : false
            }
            onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
            onDelete={this.handleTraineeDelete}
          />
        ) : null}

        <ImportModal
          open={openImportModal}
          schedule={schedule || false}
          trainees={trainees || false}
          registration_forms={registration_forms || []}
          onClose={() => this.setState({ openImportModal: false })}
        />

        <Typography variant="h5" style={{ paddingBottom: "15px" }}>
          Number of Registered Students: {total ? <SuccessTag style={{ fontSize: "16px" }}>{total}</SuccessTag> : 0}
        </Typography>
        {this.renderTableActions()}
        <Table
          items={items}
          showCheckbox={true}
          emptyCell="No Student(s) Found."
          checked={checked || []}
          allCheck={allCheck}
          onChecked={(newValue) => this.setState({ checked: newValue })}
          onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
          cells={[
            { id: "region", label: "Partner", render: this.renderCell("region") },
            { id: "class_name", label: "Class Name", render: this.renderCell("class_name") },
            { id: "created_on", label: "Registration Date", render: this.renderCell("created_on") },
            { id: "email", label: "Email", render: this.renderCell("email") },
            { id: "name", label: "Name", render: (item) => <StudentNameToDatabase {...item} /> },
            {
              id: "gender",
              label: "Gender",
              render: (item) => <WrapWord style={{ textTransform: "capitalize" }}>{item.gender || "-"}</WrapWord>,
            },
            { id: "age", label: "Age", render: this.renderCell("age") },
            { id: "org", label: "School", render: (item) => <WrapWord>{item.org || "-"}</WrapWord> },
            {
              id: "medical_condition",
              label: "Medical Condition",
              render: (item) => <WrapWord>{item.medical_condition || "-"}</WrapWord>,
            },
            // { id: 'parent_details', label: 'Parent Details', render: this.renderCell('parent_details') },
            {
              id: "trial",
              label: "Want Trial?",
              render: (item) => (item.trial && item.trial === "yes" ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />),
            },
            {
              id: "enrollment_status",
              label: "Class Status",
              render: (item) => (
                <div>
                  {this.renderEnrollmentStatus("enrollment_status", item)}
                  <UndeleteStudent
                    student={item}
                    onStudentUndelete={() => {
                      this.props.dispatch(undeleteTrainee(item.id, { schedule_id: schedule.id }));
                    }}
                  />
                </div>
              ),
            },
            { id: "remarks", label: "Registration Remarks", render: this.renderCell("remarks") },
          ]}
          actionStyles={{ width: "10%" }}
          actions={(item) => (
            <ButtonGroup>
              {item.enrollment_status && item.enrollment_status === "delete" ? null : (
                <InfoButton key="edit" size="small" onClick={() => this.setState({ openViewModal: true, modalData: item })}>
                  <i className="fa fa-edit"></i>Edit
                </InfoButton>
              )}
              {item.enrollment_status && item.enrollment_status === "confirmed" ? <StudentSyncDataByTraineeID trainee={item} /> : null}
              {/* { authData && hasAccessRights( authData, ['scd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null } */}
            </ButtonGroup>
          )}
        />
        {this.renderPagination(total)}
      </div>
    );
  }
}

export default compose(connect(), withRouter)(StudentsTable);
