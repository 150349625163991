export const programSchema = [
  {
    id: "id",
    label: "ID",
    required: ["update", "delete"],
    default: "",
    type: "string",
    validation: "string_id",
    skip: ["add"],
  },
  {
    id: "name",
    label: "Product Name",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "text",
    skip: false,
  },
  {
    id: "course_type",
    label: "Delivery Type",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "select",
    disabled: ["update"],
    skip: false,
  },
  {
    id: "course_type_label",
    label: "Delivery Type Label",
    required: false,
    default: "",
    type: "system_data",
    skip: false,
  },
  {
    id: "course_duration",
    label: "Duration",
    required: ["add", "update"],
    default: "",
    type: "string",
    field: "select",
    disabled: ["update"],
    skip: false,
  },
  {
    id: "course_duration_label",
    label: "Duration Label",
    required: false,
    default: "",
    type: "system_data",
    skip: false,
  },
  {
    id: "currency",
    label: "Payment Currency",
    required: false,
    default: "BkY4FcjfQ",
    type: "string",
    field: "select",
    skip: false,
  },
  {
    id: "currency_label",
    label: "Payment Currency Label",
    required: false,
    default: "MYR",
    type: "system_data",
    skip: false,
  },
  {
    id: "price_per_pax_id",
    label: "Price Per Pax",
    required: ["update"],
    default: "",
    type: "string",
    field: "select",
    skip: false,
  },
  {
    id: "price_per_pax",
    label: "Price Per Pax (Public-facing)",
    required: false,
    default: "",
    type: "string",
    field: "tinymce_editor",
    disabled: ["add", "update"],
    skip: false,
  },

  {
    id: "web_overview",
    label: "Program Overview",
    required: false,
    default: "",
    type: "string",
    field: "tinymce_editor",
    skip: false,
  },
  {
    id: "web_what_you_learn",
    label: "What will you learn?",
    required: false,
    default: "",
    type: "string",
    field: "tinymce_editor",
    skip: false,
  },
  {
    id: "products",
    label: "Products",
    required: false,
    default: [],
    type: "array_ids",
    field: "checkboxes",
    skip: false,
  },
  {
    id: "program_visibility",
    label: "Program Visibility",
    required: false,
    default: [],
    type: "array_ids",
    field: "checkboxes",
    skip: false,
  },
  {
    id: "registration_form",
    label: "Registration Form Template",
    required: false,
    default: "default",
    type: "string",
    field: "select",
    skip: false,
  },
  {
    id: "team_module",
    label: "Enable Competition Module",
    required: false,
    default: "no",
    type: "string",
    field: "radio",
    skip: false,
  },
  {
    id: "team_size",
    label: "Total Team Size",
    required: false,
    default: "4",
    type: "string",
    field: "select",
    skip: false,
  },
  {
    id: "team_registration",
    label: "Enable Team Registration",
    required: false,
    default: "no",
    type: "string",
    field: "select",
    dependency: { schema: "program", id: "team_registration" },
    sync: false,
  },
  {
    id: "project_submissions",
    label: "Total Project Submissions",
    required: false,
    default: "3",
    type: "string",
    field: "select",
    skip: false,
  },

  {
    id: "created_on",
    label: "Created On",
    required: false,
    default: 0,
    type: "system_date",
    skip: ["update"],
  },
  {
    id: "modified_on",
    label: "Modified On",
    required: false,
    default: 0,
    type: "system_date",
  },
  {
    id: "last_modified_by",
    label: "Modified By",
    required: false,
    default: "",
    type: "system_modified_by",
  },
];
