/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import amber from "@material-ui/core/colors/amber";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _remove from "lodash/remove";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _toString from "lodash/toString";

import Table from "../../components/Table";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
// import ButtonActions from '../ButtonActions';
// import ToolTipInfo from '../../components/ToolTipInfo';

import {
  InfoButton,
  SuccessButton,
  GreyButton,
  ButtonGroup,
  InfoLink,
  ASuccessLink,
  SuccessLink,
  InverseButton,
  ErrorButton,
} from "../../styles/button";
// import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from '../../styles/tag';
import { WarningBox } from "../../styles/message";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import {
  cloneCollections,
  doPagination,
  doArraySearch,
  getSelectOptions,
  getSelectValues,
  getSelectedValue,
  compareString,
} from "../../helpers/data";
import { getStateValue } from "../../helpers/registration_form";
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
// import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { STATE_OPTIONS } from "../../data/const_states";
import theme from "../../theme";

const AddNewWrapper = styled.div`
  display: block;
  text-aiign: right;
  padding-top: 15px;

  & > span {
    display: block;
    text-align: right;
  }
`;

const AddNewForm = styled.div`
  display: flex;
  padding-top: 5px;
  align-items: center;
  justify-content: space-between;

  .MuiOutlinedInput-input {
    padding: 5px 10px !important;
  }

  button {
    margin-left: 5px;
    padding: 4px 8px;
  }
`;

const AddNewMember = ({ formData, trainees = [], trainessInTeams = [], onChange }) => {
  const [addNew, setAddNew] = useState(false);
  const [selected, setSelected] = useState("");

  const availableStudents = useMemo(() => {
    const list =
      trainees && isArrayExists(trainees)
        ? _filter(trainees, (t) => {
            return !_find(trainessInTeams, { id: t.id }) ? true : false;
          })
        : [];
    return _sortBy(list, [(t) => t.name.toLowerCase()]);
  }, [trainees, trainessInTeams]);

  const handleAddNew = () => {
    let newTrainees = formData.trainees && isArrayExists(formData.trainees) ? [...formData.trainees] : [],
      selectedTrainee = trainees && isArrayExists(trainees) && selected && !_isEmpty(selected) ? _find(trainees, { id: selected }) : false;
    if (selectedTrainee) {
      newTrainees.push({
        id: selectedTrainee.id,
        name: selectedTrainee.name || "",
        email: selectedTrainee.email || "",
        org: selectedTrainee.org || "",
      });

      onChange(newTrainees, "trainees");
      setAddNew(false);
      setSelected("");
    } else {
      triggerErrorAlert("Please select a student to add");
    }
  };

  const availableStudentsOptions = useMemo(() => {
    let options = [];
    if (availableStudents && isArrayExists(availableStudents)) {
      _forEach(availableStudents, (student) => {
        let allowed = true;
        // make sure student isn't deleted
        if (student && student.enrollment_status && student.enrollment_status === "delete") {
          allowed = false;
        }
        if (allowed) {
          options.push({
            value: student.id,
            label: student.name + (student.org && !_isEmpty(student.org) ? " (" + student.org + ")" : ""),
          });
        }
      });
    }
    return options;
  }, [availableStudents]);

  return (
    <AddNewWrapper>
      {addNew ? (
        <>
          <span>
            <InverseButton size="small" onClick={() => setAddNew(false)}>
              Cancel
            </InverseButton>
          </span>
          <AddNewForm>
            <div style={{ width: "100%" }}>
              <FormSelect2
                label=""
                value={selected}
                placeholder="Select a student"
                isClearable={true}
                isSearchable={true}
                menuPortalTarget={document.body}
                styles={{
                  // menu: (currentStyle) => ({ ...currentStyle, marginTop: '24px' }),
                  // menuList: (currentStyle) => ({ ...currentStyle, height: '250px' }),
                  menuPortal: (currentStyle) => ({ ...currentStyle, zIndex: "9999" }),
                }}
                options={availableStudentsOptions}
                onChange={(newValue) => setSelected(newValue)}
              />
            </div>
            <SuccessButton size="small" minWidth="0px" noIconMargin="yes" onClick={handleAddNew}>
              <i className="fa fa-check"></i>
            </SuccessButton>
          </AddNewForm>
        </>
      ) : (
        <span>
          <InfoButton size="small" onClick={() => setAddNew(true)}>
            Add New
          </InfoButton>
        </span>
      )}
    </AddNewWrapper>
  );
};

const getNewTeamData = (item) => {
  return {
    ...item,
  };
};

const getTeacherMentorOptions = (list) => {
  let options = [];
  if (list && isArrayExists(list)) {
    _forEach(
      _sortBy(list, (i) => i.name.toLowerCase()),
      (item) => {
        options.push({
          value: item.email,
          label: item.name + (item.school && !_isEmpty(item.school) ? " (" + item.school + ")" : ""),
        });
      }
    );
  }
  return options;
};

const statesOptions = () => {
  const selected = _find(STATE_OPTIONS, { country: "Malaysia" });
  let options = [{ value: "", label: "Select an Option" }];
  if (selected && selected.states && isArrayExists(selected.states)) {
    _forEach(selected.states, (stateName) => {
      options.push({
        value: stateName,
        label: stateName,
      });
    });
  } // end - selected.states
  return options;
};

const Form = ({
  authData,
  view = "student",
  you = {},
  type,
  item,
  trainee,
  trainees,
  team,
  teams,
  teachers,
  mentors,
  onTypeChange,
  onFormUpdate,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    switch (type) {
      case "create":
        setFormData(
          getNewTeamData({
            ...item,
            teacher: you && you.role && you.role === "teacher" ? you.email : "",
            mentor: you && you.role && you.role === "mentor" ? you.email : "",
          })
        );
        break;
      case "join":
        setFormData({ id: "", passcode: "" });
        break;
      case "edit":
      default:
        const newTeam = { ...team, projects: getTeamProjects(team, item) };
        setFormData({ ...newTeam });
        onFormUpdate({ ...newTeam });
        break;
    }
  }, [type, team, you, item]);

  const canUpdateTeamMember = useMemo(() => {
    return view === "admin" || (you && you.role && you.role === "teacher") ? true : false;
  }, [view, you]);

  const trainessInTeams = useMemo(() => {
    let list = [];
    if (teams && isArrayExists(teams)) {
      _forEach(teams, (t) => {
        if (t.trainees && isArrayExists(t.trainees)) {
          _forEach(t.trainees, (tt) => {
            if (!_find(list, { id: tt.id })) {
              list.push({
                id: tt.id,
              });
            }
          });
        }
      });
    }
    return list;
  }, [teams]);

  // const teamProjects = useMemo(() => {
  //     const projectSize = ( item.project_submissions && !_isEmpty( item.project_submissions ) ? parseInt( item.project_submissions ) : 3 );
  //     let projects = [];
  //     for (let index = 1; index <= projectSize; index++) {
  //         const project_id = _toString( index );
  //         const project = _find( team.projects, p => compareString( p.id, project_id ) );
  //         projects.push({
  //             id: project_id,
  //             title: ( project && project.title || '' ),
  //             desc: ( project && project.desc || '' ),
  //             lms_course_id: ( item.lms_course_id || '' )
  //         });
  //     }
  //     return projects;
  // }, [ team, item ]);

  const getTeamProjects = (team, item) => {
    const projectSize = item.project_submissions && !_isEmpty(item.project_submissions) ? parseInt(item.project_submissions) : 3;
    let projects = [];
    for (let index = 1; index <= projectSize; index++) {
      const project_id = _toString(index);
      const project = _find(team.projects, (p) => compareString(p.id, project_id));
      projects.push({
        id: project_id,
        title: (project && project.title) || "",
        desc: (project && project.desc) || "",
        lms_course_id: item.lms_course_id || "",
      });
    }
    return projects;
  };

  const handleFormUpdate = (newValue, name) => {
    const newFormData = { ...formData, [name]: newValue };
    setFormData(newFormData);
    onFormUpdate(newFormData);
  };

  const handleStudentRemove = (trainee) => (event) => {
    event.preventDefault();
    const answer = confirm("Are you sure you want to remove this member from the team?");
    if (answer) {
      let newTrainees = formData.trainees && isArrayExists(formData.trainees) ? [...formData.trainees] : [];
      if (_find(newTrainees, { id: trainee.id })) {
        let pulled = _remove(newTrainees, { id: trainee.id });
      }
      handleFormUpdate(newTrainees, "trainees");
    }
  };

  const handleProjectUpdate = (selected) => (newValue, key) => {
    const newFormData = { ...formData };
    const newProjects = [...newFormData.projects];
    const index = _findIndex(newProjects, { id: selected.id });
    if (index > -1) {
      newProjects[index][key] = newValue;
    } else {
      newProjects.push({
        id: selected.id,
        title: key === "title" ? newValue : selected.title || "",
        desc: key === "desc" ? newValue : selected.desc || "",
        lms_course_id: item.lms_course_id || "",
      });
    }
    newFormData.projects = [...newProjects];
    setFormData(newFormData);
    onFormUpdate(newFormData);
  };

  const getTeamOptions = () => {
    let options = [];
    if (teams && isArrayExists(teams)) {
      _forEach(teams, (t) => {
        let school_name = "";
        if (t.trainees && isArrayExists(t.trainees)) {
          _forEach(t.trainees, (tt) => {
            if (tt.org && !_isEmpty(tt.org)) {
              school_name += (school_name && !_isEmpty(school_name) ? ", " : "") + tt.org;
            }
          });
        }
        options.push({ value: t.id, label: t.name + (school_name && !_isEmpty(school_name) ? " (" + school_name + ")" : "") });
      });
    }
    return options;
  };

  return (
    <Grid container spacing={2}>
      {type === "edit" && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong style={{ fontWeight: "700" }}>Team ID: </strong>
              {item.id || ""}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ padding: "10px 20px", background: amber["100"] }}>
              Pass code to join this team: <strong style={{ fontWeight: "700" }}>{item.passcode || ""}</strong>
            </Typography>
          </Grid>
        </>
      )}

      {type === "create" || type === "edit" ? (
        <>
          <Grid item xs={6}>
            {view === "admin" ? (
              <FormSelect
                label="State *"
                value={(formData && formData.state) || ""}
                name="state"
                options={statesOptions()}
                onChange={handleFormUpdate}
              />
            ) : (
              <FormSelect
                label="State *"
                value={formData && formData.state && !_isEmpty(formData.state) ? formData.state : getStateValue(trainee)}
                disabled={true}
                options={statesOptions()}
                // name="state"
                // onChange={handleFormUpdate}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormInput label="Team Name *" value={(formData && formData.name) || ""} name="name" onChange={handleFormUpdate} />
          </Grid>
          <Grid item xs={6}>
            <FormSelect2
              label="Teacher"
              value={(formData && formData.teacher) || ""}
              name="teacher"
              options={getTeacherMentorOptions(teachers)}
              placeholder="Select a teacher"
              isClearable={true}
              isSearchable={true}
              menuPortalTarget={document.body}
              disabled={you && you.role && you.role === "teacher" ? true : false}
              styles={{
                // menu: (currentStyle) => ({ ...currentStyle, marginTop: '24px' }),
                // menuList: (currentStyle) => ({ ...currentStyle, height: '250px' }),
                menuPortal: (currentStyle) => ({ ...currentStyle, zIndex: "9999" }),
              }}
              onChange={handleFormUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect2
              label="Mentor"
              value={(formData && formData.mentor) || ""}
              name="mentor"
              options={getTeacherMentorOptions(mentors)}
              placeholder="Select a mentor"
              isClearable={true}
              isSearchable={true}
              menuPortalTarget={document.body}
              disabled={you && you.role && you.role === "mentor" ? true : false}
              styles={{
                // menu: (currentStyle) => ({ ...currentStyle, marginTop: '24px' }),
                // menuList: (currentStyle) => ({ ...currentStyle, height: '250px' }),
                menuPortal: (currentStyle) => ({ ...currentStyle, zIndex: "9999" }),
              }}
              onChange={handleFormUpdate}
            />
          </Grid>
        </>
      ) : null}

      {type === "edit" || (type === "create" && canUpdateTeamMember) ? (
        <>
          <Grid item xs={6}>
            <Typography variant="h5" style={{ padding: "15px 0 0px 0" }}>
              Team Member(s):
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <AddNewMember formData={formData} trainees={trainees} trainessInTeams={trainessInTeams} onChange={handleFormUpdate} />
          </Grid>
          <Grid item xs={12}>
            <Table
              items={formData.trainees && isArrayExists(formData.trainees) ? _sortBy(formData.trainees, [(t) => t.name.toLowerCase()]) : []}
              showCheckbox={false}
              cells={[
                {
                  id: "name",
                  label: "Name",
                  render: (item) => <WrapWord>{item.name || "-"}</WrapWord>,
                },
                {
                  id: "org",
                  label: "School",
                  render: (item) => <WrapWord>{item.org || "-"}</WrapWord>,
                },
                {
                  id: "email",
                  label: "Email",
                  render: (item) => <WrapWord>{item.email || "-"}</WrapWord>,
                },
              ]}
              emptyCell="No student(s) found"
              actionStyles={{ width: "25%" }}
              actions={(item) => (
                <ButtonGroup>
                  <ASuccessLink href={`/bp/${item.id}`} size="small" minWidth="none" noIconMargin="yes" target="_blank">
                    View Backpack
                  </ASuccessLink>
                  <ErrorButton key="delete" size="small" minWidth="none" noIconMargin="yes" onClick={handleStudentRemove(item)}>
                    <i className="fa fa-trash"></i>
                  </ErrorButton>
                </ButtonGroup>
              )}
            />
          </Grid>
          {type === "edit" && (
            <Grid item xs={12}>
              <Typography variant="h5" style={{ padding: "15px 0 0px 0" }}>
                Team Submission(s):
              </Typography>
              <Table
                items={formData.projects || []}
                showCheckbox={false}
                cells={[
                  {
                    id: "project",
                    label: "Project",
                    render: (item) => {
                      return (
                        <>
                          <FormInput
                            label={`Project ${item.id} Title`}
                            value={item.title || ""}
                            name="title"
                            placeholder="Enter Project title here"
                            onChange={handleProjectUpdate(item)}
                          />
                        </>
                      );
                      //   if (you && you.role && you.role === "teacher") {
                      //     return (
                      //       <>
                      //         <FormInput
                      //           label={`Project ${item.id} Title`}
                      //           value={item.title || ""}
                      //           name="title"
                      //           placeholder="Enter Project title here"
                      //           onChange={handleProjectUpdate(item)}
                      //         />
                      //       </>
                      //     );
                      //   }
                      //   return <WrapWord>{item.id || "-"}</WrapWord>;
                    },
                  },
                  // {
                  //     id: 'lms', label: 'LMS Page', render: (item) => {
                  //         let external_tool = '';
                  //         switch( item.id ) {
                  //             case '1': external_tool = ''; break;
                  //             case '2': external_tool = ''; break;
                  //             case '3': external_tool = ''; break;
                  //             case '4': external_tool = ''; break;
                  //             case '5': external_tool = ''; break;
                  //             case '6': external_tool = ''; break;
                  //             case '7': external_tool = ''; break;
                  //             case '8': external_tool = ''; break;
                  //             case '9': external_tool = ''; break;
                  //             case '10': external_tool = ''; break;
                  //         }
                  //         return `https://lms.chumbaka.asia/courses/${item.lms_course_id}/`;
                  //     },
                  // },
                  {
                    id: "submission",
                    label: "Submission",
                    render: (item) => {
                      return (
                        <>
                          <FormInput
                            label={`Project ${item.id} Submission URL`}
                            value={item.desc || ""}
                            name="desc"
                            placeholder="Enter Submission URL here"
                            onChange={handleProjectUpdate(item)}
                          />
                        </>
                      );

                      //   if (you && you.role && you.role === "teacher") {
                      //     return (
                      //       <>
                      //         <FormInput
                      //           label={`Project ${item.id} Submission URL`}
                      //           value={item.desc || ""}
                      //           name="desc"
                      //           placeholder="Enter Submission URL here"
                      //           onChange={handleProjectUpdate(item)}
                      //         />
                      //       </>
                      //     );
                      //   }

                      //   return (
                      //     <>
                      //       {item.desc && !_isEmpty(item.desc) && item.title && !_isEmpty(item.title) ? (
                      //         <a href={item.desc} target="_blank" rel="nofollow">
                      //           {item.title}
                      //         </a>
                      //       ) : item.title && !_isEmpty(item.title) ? (
                      //         item.title
                      //       ) : null}
                      //     </>
                      //   );
                    },
                  },
                ]}
                emptyCell="No team submission(s) found"
                actions={null}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            {type === "edit" && formData && !(formData.trainees && isArrayExists(formData.trainees)) ? (
              <WarningBox>
                <i className="fa fa-exclamation-triangle"></i>There are no team members in this team. This means that if you click the
                "update" button below, this team will be deleted. All the submitted projects will be deleted, and you won't be able to claim
                any certificates given to this team.
              </WarningBox>
            ) : null}
          </Grid>
        </>
      ) : null}

      {type === "join" ? (
        <>
          {teams && isArrayExists(teams) ? (
            <>
              <Grid item xs={12}>
                <FormSelect2
                  label="Select a Team *"
                  value={(formData && formData.id) || ""}
                  name="id"
                  options={getTeamOptions()}
                  placeholder="Select a team"
                  isClearable={true}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                  styles={{
                    // menu: (currentStyle) => ({ ...currentStyle, marginTop: '24px' }),
                    // menuList: (currentStyle) => ({ ...currentStyle, height: '250px' }),
                    menuPortal: (currentStyle) => ({ ...currentStyle, zIndex: "9999" }),
                  }}
                  onChange={handleFormUpdate}
                />
              </Grid>
            </>
          ) : (
            <div style={{ padding: "10px" }}>
              No team found in your state.{" "}
              <a
                href="#"
                style={{ margin: "0 5px" }}
                onClick={(e) => {
                  e.preventDefault();
                  onTypeChange("create");
                }}
              >
                Click here
              </a>{" "}
              to create a new team
            </div>
          )}
        </>
      ) : null}
    </Grid>
  );
};

export default Form;
