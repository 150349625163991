/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import Form from "./form";
import { isClassTeacher, isClassMentor } from "./helpers";
import ModalStudent from "./modal_student";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormSelect2 from "../../components/FormSelect2";
import FormDatePicker from "../../components/FormDatePicker";
import FormDateOfBirth from "../../components/FormDateOfBirth";
import FormPhoneField from "../../components/FormPhoneField";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";
import ErrorMessage from "../../components/ErrorMessage";
import DotsLoader from "../../components/DotsLoader";

import {
  InfoButton,
  SuccessButton,
  GreyButton,
  ButtonGroup,
  InfoLink,
  SuccessLink,
  InverseButton,
  ErrorButton,
  AIndigoLink,
} from "../../styles/button";
import { GreyTag, SuccessTag, InfoTag, AmberTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { getStateValue } from "../../helpers/registration_form";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_PRINT_CERT_URL } from "../../constants";

import theme from "../../theme";

const Wrapper = styled.div``;

const StudentsTable = ({
  trainees = [],
  schedules = [],
  registrationForms = [],
  regions = [],
  badgeTypes = [],
  onStudentUpdate = () => {},
}) => {
  const [checked, setChecked] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const [filterBy, setFilterBy] = useState("all");
  const [sortBy, setSortBy] = useState("date-desc");
  const [searchterms, setSearchterms] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [modalData, setModalData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const totalRegisteredStudents = useMemo(() => _size(trainees), [trainees]);

  const { students, total } = useMemo(() => {
    let items = [...trainees],
      total = trainees.length;

    if (searchterms && !_isEmpty(searchterms)) {
      items = doArraySearch(items, searchterms, ["name", "email", "org"]);
      total = items.length;
    }

    // do filter
    if (filterBy && !_isEmpty(filterBy) && !_isEmpty(items)) {
      switch (filterBy) {
        case "noteam":
          items = _filter(items, (item) => !item.teams || !isArrayExists(item.teams) || item.teams.length === 0);
          total = items.length;
          break;
      }
    }

    // do sort
    if (sortBy && !_isEmpty(sortBy) && !_isEmpty(items)) {
      switch (sortBy) {
        case "date-desc":
          items = _sortBy(items, ["created_on"]);
          items = _reverse(items);
          break;
        case "date-asc":
          items = _sortBy(items, ["created_on"]);
          break;
        case "name-desc":
          items = _sortBy(items, ["name"]);
          items = _reverse(items);
          break;
        case "name-asc":
          items = _sortBy(items, ["name"]);
          break;
        case "email-desc":
          items = _sortBy(items, ["email"]);
          items = _reverse(items);
          break;
        case "email-asc":
          items = _sortBy(items, ["email"]);
          break;
        case "org-desc":
          items = _sortBy(items, ["org"]);
          items = _reverse(items);
          break;
        case "org-asc":
          items = _sortBy(items, ["org"]);
          break;
      }
    } // end - sortBy

    // do pagination
    items = doPagination(items, perPage, page);

    return { students: items, total: total };
  }, [trainees, sortBy, searchterms, perPage, page, filterBy]);

  const handleCertDownload = () => {
    if (!checked || !isArrayExists(checked)) {
      triggerErrorAlert("Please select at least one student");
      return;
    }

    // set to 25 limits
    if (checked.length > 25) {
      triggerErrorAlert("You can only select up to 25 students at a time");
      return;
    }

    let badgesIds = [];
    checked.forEach((selected) => {
      const trainee = trainees.find((trainee) => trainee.id === selected.id);
      if (trainee) {
        const badges = getStudentEarnedBadges(trainee);
        badges.forEach((badge) => {
          if (badge.issued_badge_id && !_isEmpty(badge.issued_badge_id)) {
            badgesIds.push(badge.issued_badge_id);
          }
        });
      }
    });

    if (badgesIds.length === 0) {
      triggerErrorAlert("No badges found in selected students");
      return;
    }

    window.open(`${PLATFORM_PRINT_CERT_URL}?ids=${badgesIds.join("_|_")}`, "_blank");
  };

  const getStudentEarnedBadges = (student) => {
    let badges = [];
    if (student && student.showcase_data && isArrayExists(student.showcase_data)) {
      student.showcase_data.forEach((showcase) => {
        const id = showcase.id;
        // if id = 3, skip
        if (id === "3" || id === 3) {
          return;
        }

        const issued_badge_id =
          showcase.badge && showcase.badge.issued_badge_id && !_isEmpty(showcase.badge.issued_badge_id)
            ? showcase.badge.issued_badge_id
            : false;
        const approved_badge_type =
          showcase.badge && showcase.badge.approved_badge_type && !_isEmpty(showcase.badge.approved_badge_type)
            ? showcase.badge.approved_badge_type
            : false;
        const nominated_badge_type =
          showcase.badge && showcase.badge.nominated_badge_type && !_isEmpty(showcase.badge.nominated_badge_type)
            ? showcase.badge.nominated_badge_type
            : false;

        if (nominated_badge_type || approved_badge_type || issued_badge_id) {
          let badge_status = "pending";
          if (issued_badge_id) {
            badge_status = "issued";
          } else if (approved_badge_type) {
            badge_status = "approved";
          } else if (nominated_badge_type) {
            badge_status = "nominated";
          }
          badges.push({
            id,
            status: badge_status,
            issued_badge_id,
            approved_badge_type,
            nominated_badge_type,
          });
        }
      });
    }
    return badges;
  };

  const renderCell = (key) => (item) => {
    switch (key) {
      case "age":
        return item.date_of_birth_string && !_isEmpty(item.date_of_birth_string) ? getAge(item.date_of_birth_string) : "-";
      case "created_on":
        return <WrapWord>{item[key] ? getMomentTime(item[key]._seconds * 1000, "DD MMM YYYY") : "---"}</WrapWord>;
      case "class_name":
        let schedule = schedules ? _find(schedules, { id: item.schedule_id }) : false;
        return <WrapWord>{schedule && schedule.class_name ? schedule.class_name : ""}</WrapWord>;
      case "role":
        const iamField = item.custom_fields && isArrayExists(item.custom_fields) ? _find(item.custom_fields, { type: "iam" }) : false;
        return <WrapWord>{iamField && iamField.value ? iamField.value : "-"}</WrapWord>;
      case "team":
        const teamName = item.teams && isArrayExists(item.teams) && item.teams[0] && item.teams[0].name ? item.teams[0].name : "-";
        return <WrapWord>{teamName}</WrapWord>;
      case "badge_earned":
        const badges = getStudentEarnedBadges(item);
        return badges.length > 0
          ? badges.map((badge) => {
              if (badge.status === "issued") {
                const badgeType = badgeTypes.find((type) => type.id === badge.approved_badge_type);
                return (
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <SuccessTag>{badgeType && badgeType.label ? badgeType.label : ""}</SuccessTag>
                    <AIndigoLink href={`/b/${badge.issued_badge_id}`} target="_blank" size="small">
                      <i className="fa fa-external-link"></i> View
                    </AIndigoLink>
                  </div>
                );
              } else if (badge.status === "approved") {
                const badgeType = badgeTypes.find((type) => type.id === badge.approved_badge_type);
                return (
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <InfoTag>{badgeType && badgeType.label ? badgeType.label : ""} (Approved)</InfoTag>
                  </div>
                );
              } else if (badge.status === "nominated") {
                const badgeType = badgeTypes.find((type) => type.id === badge.nominated_badge_type);
                return (
                  <div style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <AmberTag>{badgeType && badgeType.label ? badgeType.label : ""} (Nominated)</AmberTag>
                  </div>
                );
              } else {
                return <GreyTag>Pending</GreyTag>;
              }
            })
          : "-";
      default:
        return <WrapWord>{item[key] || "-"}</WrapWord>;
    }
  };

  return (
    <Wrapper>
      <Typography variant="h5" style={{ paddingBottom: "15px" }}>
        Number of Registered Students:{" "}
        {totalRegisteredStudents ? <SuccessTag style={{ fontSize: "16px" }}>{totalRegisteredStudents}</SuccessTag> : 0}
      </Typography>
      <TableBar
        filterBy={filterBy}
        sortBy={sortBy}
        perPage={perPage}
        searchterms={searchterms}
        sortByOptions={[
          { value: "date-desc", label: "Registration Date (Recent First)" },
          { value: "date-asc", label: "Registration Date (Oldest First)" },
          { value: "name-asc", label: "Name ( A - Z)" },
          { value: "name-desc", label: "Name ( Z - A )" },
          { value: "email-asc", label: "Email ( A - Z)" },
          { value: "email-desc", label: "Email ( Z - A )" },
          { value: "org-asc", label: "School ( A - Z)" },
          { value: "org-desc", label: "School ( Z - A )" },
        ]}
        filterByOptions={[
          { value: "all", label: "All Students" },
          { value: "noteam", label: "Students without a team" },
        ]}
        leftWidth="35%"
        rightWidth="65%"
        leftButtons={
          checked && isArrayExists(checked)
            ? [
                <div key="check_option" style={{ marginLeft: "15px", paddingTop: "5px" }}>
                  <GreyButton
                    style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }}
                    onClick={() => {
                      setChecked([]);
                      setAllCheck(false);
                    }}
                  >
                    <i className="fa fa-remove" style={{ marginRight: "10px" }}></i>
                    {_size(checked) + " selected"}
                  </GreyButton>
                </div>,
              ]
            : null
        }
        rightButtons={[
          <ButtonActions
            key="actions"
            label="Actions"
            menuContainerStyle={{ width: "250px" }}
            style={{ marginRight: "5px" }}
            actions={[
              {
                id: "downloadcerts",
                label: "Download Certificates",
                icon: "fa-cloud-download",
                onClick: handleCertDownload,
              },
            ]}
          />,
        ]}
        onEntriesChange={(newPerPage) => {
          setPerPage(newPerPage);
          setPage(1);
        }}
        onSearchChange={(terms) => {
          setSearchterms(terms);
          setPage(1);
        }}
        onSortByChange={(newSortBy) => {
          setSortBy(newSortBy);
        }}
        onFilterByChange={(newFilterBy) => {
          setFilterBy(newFilterBy);
          setPage(1);
        }}
        style={{ marginBottom: "20px" }}
      />
      <Table
        items={students}
        showCheckbox={true}
        emptyCell="No Student(s) Found."
        checked={checked || []}
        allCheck={allCheck}
        onChecked={(newValue) => setChecked(newValue)}
        onAllChecked={(newValue) => setAllCheck(newValue)}
        cells={[
          { id: "class_name", label: "Class Name", render: renderCell("class_name") },
          { id: "created_on", label: "Registration Date", render: renderCell("created_on") },
          {
            id: "role",
            label: "Role",
            render: renderCell("role"),
          },
          { id: "email", label: "Email", render: renderCell("email") },
          { id: "name", label: "Name", render: renderCell("name") },
          {
            id: "gender",
            label: "Gender",
            render: (item) => <WrapWord style={{ textTransform: "capitalize" }}>{item.gender || "-"}</WrapWord>,
          },
          { id: "age", label: "Age", render: renderCell("age") },
          { id: "org", label: "School", render: (item) => <WrapWord>{item.org || "-"}</WrapWord> },
          {
            id: "team",
            label: "Team",
            render: renderCell("team"),
          },
          {
            id: "badge_earned",
            label: "Badge Earned",
            render: renderCell("badge_earned"),
          },
        ]}
        actionStyles={{ width: "20%" }}
        actions={(item) => (
          <ButtonGroup>
            {item.enrollment_status && item.enrollment_status === "delete" ? null : (
              <InfoButton
                key="edit"
                size="small"
                onClick={() => {
                  setModalData(item);
                  setModalOpen(true);
                }}
              >
                <i className="fa fa-edit"></i>Edit
              </InfoButton>
            )}
            <AIndigoLink href={`/bp/${item.id}`} target="_blank" size="small">
              <i className="fa fa-eye"></i> View Backpack
            </AIndigoLink>
          </ButtonGroup>
        )}
      />
      <Pagination
        total={total}
        perPage={perPage}
        page={page}
        style={{ marginTop: "20px" }}
        doneLoaded={true}
        onPageChange={(newPage) => setPage(newPage)}
      />

      <ModalStudent
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalData(false);
        }}
        student={modalData}
        schedules={schedules}
        registrationForms={registrationForms}
        regions={regions}
        onStudentUpdate={(formData) => {
          onStudentUpdate(formData);
          setModalOpen(false);
          setModalData(false);
        }}
        onFormUpdate={(newData) => {
          setModalData(newData);
        }}
      />
    </Wrapper>
  );
};

export default StudentsTable;
