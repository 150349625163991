/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import shortid from "shortid";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _replace from "lodash/replace";
import _remove from "lodash/remove";
import _reverse from "lodash/reverse";

import RegistrationCompleted from "./registration_completed";
import StudentForm from "./student_form";
import ParentForm from "./parent_form";
import SourceForm from "./source_form";
import TeamForm from "./team_form";

import ScreenLoader from "../../components/ScreenLoader";
import ModalDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Footer from "../../components/Footer";

import { isArrayExists } from "../../helpers/validation";
import { getSelectedValue, cloneCollections, trimEmail } from "../../helpers/data";
import { getMomentTime, printSemiPrettyDate } from "../../helpers/date";
import { isSkip, isSchemaRequired, doSchemaErrorCheck } from "../../helpers/schemas";
import { triggerErrorAlert } from "../../helpers/alert";

import { FullScreenFlex, FullScreenComponent } from "../../styles/division";
import { Button, InverseButton, GreyButton, InverseLink, InfoButton, InfoLink, AInverseLink } from "../../styles/button";

import { getCourseRegistrationData, doCourseRegistration } from "../../actions/course_registration";
import { toggleLoader } from "../../actions/global";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_NAME_LONG, DAY_OPTIONS, GENDER_OPTIONS, PLATFORM_WEBSITE_URL, DEV_MODE } from "../../constants";

const MainWrapper = styled.div`
  max-width: 900px;
  margin: 0px auto;
  padding: 60px 20px;
`;

const InfoWrapper = styled.div`
    padding: 0px 20px;
    max-width: 600px;
    color: #999;
    font-size: 12px;

    p {
        margin-bottom: 15px;
        &:last-child { margin-bottom: 0px; }
    }

    a {
        color: ${blue["700"]};
        font-weight: 700;
        text-decoration: underline;

        &:hover {
            color: color: ${blue["500"]};
        }
    }
`;

const ProgramLeftCol = styled.div``;

const ProgramRightCol = styled.div`
  background: #f1f1f1;
  padding: 20px;

  & > div {
    position: relative;
    padding-left: 50px;
  }
`;

const ProgramBox = styled.div`
  margin-bottom: 20px;
  border-radius: 6px;

  h5 {
    margin-bottom: 5px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

const ProgramIcon = styled.span`
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  z-index: 2;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  background: #212121;
  color: #fff;
  border-radius: 100%;
`;

const ProgramOverviewWrapper = styled.div`
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }
`;

const useStyles = (theme) => ({
  boxheading: {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.background,
    paddingBottom: "15px",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
    textTransform: "uppercase",
  },
  paragraph: {
    fontSize: "16px",
    fontWeight: "400",
    marginBottom: "15px",
  },
  tooltip: { maxWidth: "600px" },
  wrapperpaper: {
    padding: "30px 20px",
    background: "#fff",
    color: theme.palette.background,
  },
  table: {
    border: "1px solid " + theme.palette.background,
    "& tr:last-child > td": {
      borderColor: theme.palette.background,
    },
  },
  hcell: {
    width: "30%",
    fontWeight: "700",
    fontSize: "16px",
    verticalAlign: "top",
    color: theme.palette.background,
  },
  tcell: {
    width: "70%",
    fontWeight: "400",
    fontSize: "14px",
    verticalAlign: "top",
    color: theme.palette.background,
  },
  whatsapp: {
    display: "inline-block",
    marginLeft: "8px",
    color: "#fff",
    borderRadius: "100%",
    verticalAlign: "middle",
    fontSize: "16px",
    width: "26px",
    height: "26px",
    lineHeight: "26px",
    textAlign: "center",
    backgroundColor: "#25D366",
    "&:hover": {
      backgroundColor: "#128C7E",
    },
  },
});

const getDefaultState = () => {
  let state = {
    trainees: [],
    parent: {
      parent_name: "",
      parent_email: "",
      parent_phone: "",
      parent_city: "",
      parent_role: "Guardian",
    },
    parent_hear_source: [],
    teams: [],
    checkbox_consent: "no",
    checkbox_class_policies: "no",
    checkbox_privacy_policy: "no",
    checkbox_newsletter: "yes",
    data: false,
    error: false,
    submitted: false,
    pastRegistrations: [],
    openDeleteModal: false,
    deleteModal: false,
    randNum: false,
  };
  return cloneCollections(state);
};

class CourseRegistrationPage extends React.Component {
  state = getDefaultState();

  componentDidMount() {
    const { schedule_id } = this.props.match.params;

    this.props.dispatch(getCourseRegistrationData(schedule_id));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { randNum, regError, regData, regUpdated, pastRegistrations = [] } = this.props;
    if (randNum && randNum !== this.state.randNum) {
      if (regError) {
        this.setState({ error: regError, randNum });
      } else if (regUpdated && regUpdated === randNum) {
        this.setState({ submitted: true, randNum, pastRegistrations });
      } else if (regData) {
        this.setState({
          data: regData,
          trainees: [this.getDefaultStudentVal(regData.schedule || false)],
          randNum,
        });
      }
    } // end - randNum
  }

  componentWillUnmount() {
    this.props.dispatch(resetRedux("course_registration"));
  }

  handleRefresh = () => {
    const { schedule_id } = this.props.match.params;
    this.props.dispatch(toggleLoader(true));
    this.props.dispatch(resetRedux("course_registration"));
    setTimeout(() => {
      this.setState(getDefaultState());
      this.props.dispatch(toggleLoader(false));
      this.props.dispatch(getCourseRegistrationData(schedule_id));
    }, 300);
  };

  handleFormSubmit = () => {
    const { schedule_id } = this.props.match.params;
    const {
      trainees,
      teams,
      parent,
      parent_hear_source,
      checkbox_consent,
      checkbox_class_policies,
      checkbox_privacy_policy,
      checkbox_newsletter,
      data,
    } = this.state;
    let formData = {
        schedule_id,
        trainees: [],
        teams: teams && isArrayExists(teams) ? [...teams] : [],
      },
      emails = [],
      error = false;

    if (trainees && isArrayExists(trainees)) {
      _forEach(trainees, (trainee) => {
        let student = {};
        traineeSchema.forEach((schema) => {
          switch (schema.id) {
            case "email":
              student[schema.id] = trainee && trainee[schema.id] ? trimEmail(trainee[schema.id]) : schema.default;
              if (student[schema.id] && !_isEmpty(student[schema.id])) {
                // make sure all the emails entered are unique
                if (emails && isArrayExists(emails) && _find(emails, { email: student[schema.id] })) {
                  error = "Each students MUST have a unique email address.";
                } else {
                  emails.push({ email: student[schema.id] });
                }
              } // end - student[schema.id]
              break;
            case "parent_email":
              student[schema.id] = parent && parent[schema.id] ? trimEmail(parent[schema.id]) : schema.default;
              break;
            case "parent_name":
            case "parent_phone":
            case "parent_city":
            case "parent_role":
              student[schema.id] = parent && parent[schema.id] ? parent[schema.id] : schema.default;
              break;
            case "parent_hear_source":
              student[schema.id] =
                parent_hear_source && isArrayExists(parent_hear_source) ? cloneCollections(parent_hear_source) : schema.default;
              break;
            case "newsletter":
              student[schema.id] = checkbox_newsletter && checkbox_newsletter === "yes" ? "yes" : "no";
              break;
            default:
              student[schema.id] = trainee && trainee[schema.id] ? trainee[schema.id] : schema.default;
              break;
          }
          // check if all is filled
          if (!doSchemaErrorCheck(student[schema.id], schema, "add")) {
            error = 'Please fill out "' + schema.label + '" field for Student Details';
            // error = 'Please fill out all the required information for Student Details';
          }
        });

        // do custom field check
        if (data.schedule && data.schedule.registration_form_fields && isArrayExists(data.schedule.registration_form_fields)) {
          // create custom fields holder
          student.custom_fields = [];
          _forEach(data.schedule.registration_form_fields, (field) => {
            let fieldVal = "";
            switch (field.type) {
              case "email":
              case "name":
              case "gender":
              case "date_of_birth_string":
                // skip
                break;
              case "org":
              case "medical_condition":
              case "trial":
                if (field.required && field.required === "yes") {
                  if (!(student[field.type] && !_isEmpty(student[field.type])))
                    error = 'Please fill out "' + field.label + '" field for Student Details';
                }
                break;
              default:
                fieldVal = trainee && trainee["cf_" + field.id] ? trainee["cf_" + field.id] : "";
                student.custom_fields.push({
                  id: field.id,
                  value: fieldVal,
                });
                if (field.required && field.required === "yes") {
                  if (!(fieldVal && !_isEmpty(fieldVal))) error = 'Please fill out "' + field.label + '" field for Student Details';
                } // end - field.required
                break;
            }
          });
        } // end - data.schedule.registration_form_fields

        formData.trainees.push(student);
      });
    } else {
      error = "Please insert at least one student details";
    } // end - trainees

    // check if parent info is required
    if (this.checkIfParentInfoIsRequired()) {
      if (
        !(parent && parent.parent_name && !_isEmpty(parent.parent_name)) ||
        !(parent && parent.parent_email && !_isEmpty(parent.parent_email)) ||
        !(parent && parent.parent_phone && !_isEmpty(parent.parent_phone)) ||
        !(parent && parent.parent_city && !_isEmpty(parent.parent_city))
      )
        error = "Please fill out all the required information for Guardian's Contact";
    } // end - checkIfParentInfoIsRequired

    // check for agreement checkboxes
    if (!(checkbox_privacy_policy && checkbox_privacy_policy === "yes")) error = "You must read and agreed to our Privacy Policy.";

    if (!(checkbox_class_policies && checkbox_class_policies === "yes")) error = "You must read and agreed to our Class Policies.";

    if (!(checkbox_consent && checkbox_consent === "yes")) error = "You must read and agreed to the Parental Consent.";

    if (error) {
      triggerErrorAlert(error);
    } else {
      this.props.dispatch(doCourseRegistration(formData));
    } // end - error
  };

  handleAddNewStudent = (event) => {
    event.preventDefault();
    const { trainees, data } = this.state;
    let newTrainees = trainees && isArrayExists(trainees) ? cloneCollections(trainees) : [];
    newTrainees.push(this.getDefaultStudentVal(data.schedule || false));
    this.setState({ trainees: newTrainees });
  };

  checkIfParentEmailisthesameasStudents = (parent_email) => {
    const { trainees } = this.state;
    let same = false;
    _forEach(trainees, (trainee) => {
      if (trainee && trainee.email && !_isEmpty(trainee.email) && trimEmail(trainee.email) === trimEmail(parent_email)) {
        same = true;
      }
    });
    return same;
  };

  getStudentEmails = () => {
    const { trainees } = this.state;
    let emails = "";
    _forEach(trainees, (trainee) => {
      if (trainee && trainee.email && !_isEmpty(trainee.email)) {
        emails += (!_isEmpty(emails) ? ", " : "") + trimEmail(trainee.email);
      }
    });
    return emails;
  };

  getDefaultStudentVal = (schedule) => {
    let student = { id: shortid.generate() };
    _forEach(traineeSchema, (schema) => {
      if (!isSkip(schema, "add")) {
        // for schedule dependencies
        if (
          schedule &&
          schema &&
          schema.dependency &&
          schema.dependency.schema &&
          schema.dependency.schema === "schedule" &&
          schema.dependency.id &&
          !_isEmpty(schema.dependency.id)
        ) {
          student[schema.id] = schedule[schema.dependency.id] ? schedule[schema.dependency.id] : schema.default || "";
        } else {
          switch (schema.id) {
            case "schedule_id":
              student[schema.id] = schedule && schedule.id ? schedule.id : "";
              break;
            default:
              student[schema.id] = schema.default || "";
              break;
          }
        } // end - schedule
      }
    });
    return student;
  };

  checkIfParentInfoIsRequired = () => {
    const { trainees } = this.state;
    let required = false;
    if (trainees && isArrayExists(trainees)) {
      _forEach(trainees, (trainee) => {
        if (trainee && trainee.age && trainee.age < 16) required = true;
      });
    } // end - trainees
    return required;
  };

  checkIfTeamRegistrationEnabled = () => {
    const { data } = this.state;
    return data &&
      data.schedule &&
      data.schedule.team_module &&
      data.schedule.team_module === "yes" &&
      data.schedule.team_registration &&
      data.schedule.team_registration === "yes"
      ? true
      : false;
  };

  renderStudentForm = (student) => {
    const { trainees, data } = this.state;
    return (
      <StudentForm
        student={student}
        schedule={(data && data.schedule) || false}
        trainees={trainees || []}
        onUpdate={(trainees) => this.setState({ trainees })}
        key={student.id}
      />
    );
  };

  renderRegistrationForm = () => {
    const { classes } = this.props;
    const { trainees, parent, data, teams } = this.state;
    return (
      <div style={{ marginTop: "30px" }}>
        <Typography variant="h4" className={classes.boxheading}>
          PARTICIPANT INFORMATION
        </Typography>
        {trainees && isArrayExists(trainees) ? trainees.map(this.renderStudentForm) : null}
        <div style={{ padding: "15px 10px" }}>
          <InverseButton
            minWidth="0px"
            noIconMargin="yes"
            onClick={this.handleAddNewStudent}
            style={{ marginRight: "8px", verticalAlign: "middle", padding: "5px 8px" }}
          >
            <i className="fa fa-plus"></i>
          </InverseButton>
          Add another participant
        </div>
        {this.checkIfTeamRegistrationEnabled() ? (
          <div style={{ padding: "20px", marginTop: "20px", border: "2px solid #212121", borderRadius: "6px" }}>
            <Typography variant="h4" className={classes.boxheading}>
              Register Your Team
            </Typography>
            <TeamForm
              trainees={trainees}
              schedule={data.schedule}
              teams={teams}
              onUpdate={(teams) => {
                this.setState({ teams });
              }}
            />
          </div>
        ) : null}
        {this.checkIfParentInfoIsRequired() ? (
          <div style={{ padding: "20px" }}>
            <Typography variant="h4" className={classes.boxheading}>
              GUARDIAN INFORMATION
              <div style={{ fontSize: "13px", fontWeight: "300", marginTop: "5px" }}>
                (Parent/guardian information is required for student below 16 years old)
              </div>
            </Typography>
            <ParentForm parent={parent} onUpdate={(parent) => this.setState({ parent })} />
          </div>
        ) : null}
        <div style={{ padding: "20px" }}>
          <Typography variant="h4" className={classes.boxheading}>
            How did you hear about us?
          </Typography>
          <SourceForm onUpdate={(parent_hear_source) => this.setState({ parent_hear_source })} />
        </div>
      </div>
    );
  };

  renderCourseDate = (schedule) => {
    let date = "";

    // add date
    if (schedule && schedule.rolling_class && schedule.rolling_class === "yes") {
      date += getMomentTime(schedule.start_date, "dddd");
    } else if (schedule && schedule.start_date && schedule.end_date) {
      date += printSemiPrettyDate([{ start_date: schedule.start_date, end_date: schedule.end_date }]);
    } // end - schedule

    // add time
    if (schedule && schedule.start_date && schedule.end_date) {
      date +=
        (!_isEmpty(date) ? ", " : "") + getMomentTime(schedule.start_date, "h:mma") + " - " + getMomentTime(schedule.end_date, "h:mma");
    } // end - schedule.start_date

    return date;
  };

  renderProgramDetails = () => {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Grid container spacing={2} style={{ border: "1px solid #212121", padding: "15px", borderRadius: "6px" }}>
        <Grid item xs={12} sm={7}>
          <ProgramLeftCol>
            <ProgramBox>
              <Typography variant="h5">Program Name</Typography>
              {data && data.schedule && data.schedule.program_name ? data.schedule.program_name : "-"}
            </ProgramBox>
            <ProgramBox>
              <Typography variant="h5">Program Overview</Typography>
              {data && data.schedule && data.schedule.program_overview ? (
                <ProgramOverviewWrapper>
                  <div dangerouslySetInnerHTML={{ __html: data.schedule.program_overview }} />
                </ProgramOverviewWrapper>
              ) : (
                "-"
              )}
            </ProgramBox>
          </ProgramLeftCol>
        </Grid>
        <Grid item xs={12} sm={5}>
          <ProgramRightCol>
            <ProgramBox>
              <ProgramIcon>
                <i className="fa fa-university"></i>
              </ProgramIcon>
              <Typography variant="h5">Venue</Typography>
              {data && data.schedule && data.schedule.training_location_label ? data.schedule.training_location_label : "-"}
            </ProgramBox>
            <ProgramBox>
              <ProgramIcon>
                <i className="fa fa-calendar"></i>
              </ProgramIcon>
              <Typography variant="h5">Date & Time</Typography>
              {data && data.schedule ? this.renderCourseDate(data.schedule) : "-"}
            </ProgramBox>
            <ProgramBox>
              <ProgramIcon>
                <i className="fa fa-clock-o"></i>
              </ProgramIcon>
              <Typography variant="h5">Duration</Typography>
              {data && data.schedule && data.schedule.course_duration_label ? data.schedule.course_duration_label : "-"}
            </ProgramBox>
            {data &&
            data.schedule &&
            data.schedule.show_price_per_pax &&
            data.schedule.show_price_per_pax === "yes" &&
            data.schedule.price_per_pax &&
            !_isEmpty(data.schedule.price_per_pax) ? (
              <ProgramBox>
                <ProgramIcon>
                  <i className="fa fa-money"></i>
                </ProgramIcon>
                <Typography variant="h5">Price Per Pax</Typography>
                <div dangerouslySetInnerHTML={{ __html: data.schedule.price_per_pax }} />
              </ProgramBox>
            ) : null}
            <ProgramBox>
              <ProgramIcon>
                <i className="fa fa-user"></i>
              </ProgramIcon>
              <Typography variant="h5">Coordinator</Typography>
              {data && data.schedule && data.schedule.coordinator_name && !_isEmpty(data.schedule.coordinator_name)
                ? data.schedule.coordinator_name
                : "-"}
            </ProgramBox>
            <ProgramBox>
              <ProgramIcon>
                <i className="fa fa-phone"></i>
              </ProgramIcon>
              <Typography variant="h5">Coordinator's Contact</Typography>
              {data && data.schedule && data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone) ? (
                <>
                  {data.schedule.coordinator_phone}
                  {data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone) ? (
                    <a href={"https://wa.me/" + data.schedule.coordinator_phone} target="_blank" className={classes.whatsapp}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  ) : null}
                </>
              ) : (
                "-"
              )}
            </ProgramBox>
          </ProgramRightCol>
        </Grid>
      </Grid>
    );
  };

  renderCourseDetails = () => {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.hcell}>Course Name</TableCell>
            <TableCell className={classes.tcell}>
              {data && data.schedule && data.schedule.course_name ? data.schedule.course_name : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.hcell}>Course Venue</TableCell>
            <TableCell className={classes.tcell}>
              {data && data.schedule && data.schedule.training_location_label ? data.schedule.training_location_label : ""}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.hcell}>Date & Time</TableCell>
            <TableCell className={classes.tcell}>{data && data.schedule ? this.renderCourseDate(data.schedule) : "-"}</TableCell>
          </TableRow>
          {data &&
          data.schedule &&
          data.schedule.show_price_per_pax &&
          data.schedule.show_price_per_pax === "yes" &&
          data.schedule.price_per_pax &&
          !_isEmpty(data.schedule.price_per_pax) ? (
            <TableRow>
              <TableCell className={classes.hcell}>Price Per Pax</TableCell>
              <TableCell className={classes.tcell}>
                <div dangerouslySetInnerHTML={{ __html: data.schedule.price_per_pax }} />
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell className={classes.hcell}>Coordinator</TableCell>
            <TableCell className={classes.tcell}>
              {data && data.schedule && data.schedule.coordinator_name && !_isEmpty(data.schedule.coordinator_name)
                ? data.schedule.coordinator_name
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.hcell}>Coordinator's Contact</TableCell>
            <TableCell className={classes.tcell}>
              {data && data.schedule && data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone) ? (
                <>
                  {data.schedule.coordinator_phone}
                  {data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone) ? (
                    <a href={"https://wa.me/" + data.schedule.coordinator_phone} target="_blank" className={classes.whatsapp}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  ) : null}
                </>
              ) : (
                "-"
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  renderCheckboxes = () => {
    const { checkbox_consent, checkbox_class_policies, checkbox_privacy_policy, checkbox_newsletter } = this.state;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox_consent && checkbox_consent === "yes" ? true : false}
                  onChange={(event) => this.setState({ checkbox_consent: event.target.checked ? "yes" : "no" })}
                  value={checkbox_consent}
                  color="primary"
                />
              }
              label={
                <>
                  <a href="https://www.notion.so/Parental-Consent-f2d1c75abcf5407f83e41ee58e7449b3" target="_blank">
                    The Parental Consent
                  </a>
                  ,
                </>
              }
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox_class_policies && checkbox_class_policies === "yes" ? true : false}
                  onChange={(event) => this.setState({ checkbox_class_policies: event.target.checked ? "yes" : "no" })}
                  value={checkbox_class_policies}
                  color="primary"
                />
              }
              label={
                <>
                  <a href="https://www.chumbaka.asia/class-policies/" target="_blank">
                    Class Policies
                  </a>
                  , and
                </>
              }
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox_privacy_policy && checkbox_privacy_policy === "yes" ? true : false}
                  onChange={(event) => this.setState({ checkbox_privacy_policy: event.target.checked ? "yes" : "no" })}
                  value={checkbox_privacy_policy}
                  color="primary"
                />
              }
              label={
                <>
                  <a href="https://www.chumbaka.asia/privacy-policy" target="_blank">
                    Privacy Policies
                  </a>
                  .
                </>
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox_newsletter && checkbox_newsletter === "yes" ? true : false}
                  onChange={(event) => this.setState({ checkbox_newsletter: event.target.checked ? "yes" : "no" })}
                  value={checkbox_newsletter}
                  color="primary"
                />
              }
              label={"I would like to subscribe to Chumbaka's quarterly newsletter."}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  renderForm() {
    const { openDeleteModal, deleteModal, data } = this.state;
    return (
      <>
        <h1 style={{ fontWeight: "300", fontSize: "32px", textTransform: "uppercase", marginBottom: "20px", textAlign: "center" }}>
          REGISTRATION
        </h1>

        {data && data.schedule && data.schedule.is_program_schedule && data.schedule.is_program_schedule === "yes"
          ? this.renderProgramDetails()
          : this.renderCourseDetails()}
        {this.renderRegistrationForm()}

        <InfoWrapper>
          <Typography variant="body1">
            A course confirmation email will be sent 2 weeks before the commencement of class. For any enquiry, please contact person in
            charge{" "}
            {data && data.schedule && data.schedule.coordinator_name && !_isEmpty(data.schedule.coordinator_name)
              ? data.schedule.coordinator_name
              : ""}{" "}
            at{" "}
            {data && data.schedule && data.schedule.coordinator_phone && !_isEmpty(data.schedule.coordinator_phone)
              ? data.schedule.coordinator_phone
              : ""}{" "}
            or email us at{" "}
            {data && data.schedule && data.schedule.coordinator_email && !_isEmpty(data.schedule.coordinator_email)
              ? data.schedule.coordinator_email
              : ""}
            .
          </Typography>
          <Typography variant="body1">By clicking “Submit" button, you hereby agree that you have read and accepted:</Typography>
          {this.renderCheckboxes()}
        </InfoWrapper>

        <div style={{ textAlign: "center", marginTop: "45px" }}>
          <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleFormSubmit}>
            <i className="fa fa-mouse-pointer"></i>Submit
          </InfoButton>
        </div>
      </>
    );
  }

  renderSubmitted() {
    const { classes } = this.props;
    const { data, trainees, parent, pastRegistrations } = this.state;
    const studentEmail = this.getStudentEmails();
    const parentEmail =
      parent && parent.parent_email && !_isEmpty(parent.parent_email) && !this.checkIfParentEmailisthesameasStudents(parent.parent_email)
        ? " & " + trimEmail(parent.parent_email)
        : "";
    return (
      <RegistrationCompleted
        data={data}
        trainees={trainees}
        parent={parent}
        classes={classes}
        studentEmail={studentEmail}
        parentEmail={parentEmail}
        pastRegistrations={pastRegistrations}
        onRefresh={() => {
          this.handleRefresh();
        }}
      />
    );
  }

  renderContent() {
    const { classes } = this.props;
    const { submitted } = this.state;
    return (
      <FullScreenComponent
        style={{
          // background: 'url("/loader_bg.jpg")', backgroundPosition: "center center", backgroundSize: "100%", backgroundRepeat: 'repeat-y',
          background: "#e5e5e5",
          height: "auto",
          minHeight: "100vh",
        }}
      >
        <MainWrapper>
          <Paper elevation={2} className={classes.wrapperpaper}>
            <div style={{ margin: "0 auto 30px auto", maxWidth: "175px" }}>
              <img src="/logo.gif" style={{ display: "block", height: "auto", width: "100%" }} />
            </div>
            {submitted ? this.renderSubmitted() : this.renderForm()}
          </Paper>
        </MainWrapper>
        <Footer />
      </FullScreenComponent>
    );
  }

  renderErrorMessage = () => {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <FullScreenComponent
        style={{
          background: 'url("/loader_bg.jpg")',
          backgroundPosition: "center center",
          backgroundSize: "100%",
          backgroundRepeat: "repeat-y",
          height: "auto",
          minHeight: "100vh",
        }}
      >
        <MainWrapper>
          <Paper elevation={2} className={classes.wrapperpaper}>
            <div style={{ margin: "0 auto 30px auto", maxWidth: "175px" }}>
              <img src="/logo.gif" style={{ display: "block", height: "auto", width: "100%" }} />
            </div>
            <Typography
              variant="h4"
              style={{
                color: red["700"],
                textAlign: "center",
                background: red["50"],
                padding: "20px",
                border: "2px solid " + red["700"],
              }}
            >
              {error}
            </Typography>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <AInverseLink href={PLATFORM_WEBSITE_URL} style={{ padding: "15px 5px" }} minWidth="250px">
                <i className="fa fa-long-arrow-left"></i>Go Back to Website
              </AInverseLink>
            </div>
          </Paper>
        </MainWrapper>
        <Footer />
      </FullScreenComponent>
    );
  };

  render() {
    const { randNum, error } = this.state;
    return !randNum ? <ScreenLoader /> : error ? this.renderErrorMessage() : this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    regData: state.course_registration && state.course_registration.data ? state.course_registration.data : null,
    regUpdated: state.course_registration && state.course_registration.updated ? state.course_registration.updated : null,
    pastRegistrations:
      state.course_registration && state.course_registration.past_registrations ? state.course_registration.past_registrations : null,
    regError: state.course_registration && state.course_registration.error_message ? state.course_registration.error_message : null,
    randNum: state.course_registration && state.course_registration.data ? state.course_registration.rand : null,
  };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(CourseRegistrationPage);
