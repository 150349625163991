/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import _isEmpty from "lodash/isEmpty";

import TeamsTable from "../../modules/TeamsTable";
import TeacherStudentsTable from "../../modules/TeamsTable/teacher_students";

import AppWrapper from "../../components/AppWrapper";
import ErrorMessage from "../../components/ErrorMessage";
import FormSelect2 from "../../components/FormSelect2";
import DotsLoader from "../../components/DotsLoader";

// import { isAdminType } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { ButtonGroup, InfoButton, GreyButton } from "../../styles/button";
import { isArrayExists } from "../../helpers/validation";

import { toggleLoader } from "../../actions/global";

// import { PLATFORM_NAME } from '../../constants';

// import theme from '../../theme';

const getTeacherStudentsTotalRegistered = (schedule_id, org) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({ url: "student", action: "teacher_students_total_registered", formData: { schedule_id, org } })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateStudentDataByTeacher = (formData, schedule_id, org) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({ url: "student", action: "teacher_students_update", formData })
      .then(() => {
        return callFunctionsAPI({ url: "student", action: "teacher_students_total_registered", formData: { schedule_id, org } });
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getStudentTeams = (uid) => {
  return new Promise((resolve, reject) => {
    callFunctionsAPI({ url: "student", action: "get_teams", formData: { uid } })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const StudentTeamsProfile = ({ authData, dispatch }) => {
  const [loaded, setLoaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [student, setStudent] = useState({});
  const [studentTrainees, setStudentTrainees] = useState([]);
  const [teams, setTeams] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedTab, setSelectedTab] = useState("teams");
  const [teacherStudents, setTeacherStudents] = useState([]);
  const [teacherStudentsDataLoaded, setTeacherStudentsDataLoaded] = useState(false);
  const [registrationForms, setRegistrationForms] = useState([]);
  const [regions, setRegions] = useState([]);
  const [badgeTypes, setBadgeTypes] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (authData && authData.uid && !_isEmpty(authData.uid)) {
      getStudentTeams(authData.uid)
        .then((data) => {
          if (mounted) {
            setLoaded(true);
            setStudent((data && data.student) || {});
            setTeams((data && data.teams) || []);
            setSchedules((data && data.schedules) || []);
            setStudentTrainees((data && data.student_trainees) || []);
          }
        })
        .catch((error) => {
          if (mounted) {
            setLoaded(true);
            setErrorMsg(getAPIErrorMessage(error));
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [authData]);

  const showTeamManagement = useMemo(() => {
    let valid = false;
    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule) => {
        if (schedule.teachers && schedule.teachers.length > 0 && schedule.teachers.find((teacher) => teacher.email === authData.email)) {
          valid = true;
        }
      });
    }
    return valid;
  }, [authData, schedules]);

  const combinedTeachers = useMemo(() => {
    let teachers = [];
    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule) => {
        if (schedule.teachers && schedule.teachers.length > 0) {
          schedule.teachers.forEach((teacher) => {
            if (!teachers.find((t) => t.email === teacher.email)) {
              teachers.push(teacher);
            }
          });
        }
      });
    }
    return teachers;
  }, [schedules]);

  const combinedMentors = useMemo(() => {
    let mentors = [];
    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule) => {
        if (schedule.mentors && schedule.mentors.length > 0) {
          schedule.mentors.forEach((mentor) => {
            if (!mentors.find((m) => m.email === mentor.email)) {
              mentors.push(mentor);
            }
          });
        }
      });
    }
    return mentors;
  }, [schedules]);

  const isClassTeacher = useMemo(() => {
    if (schedules && schedules.length > 0 && selectedClass) {
      return schedules.find(
        (schedule) =>
          schedule.id === selectedClass &&
          schedule.teachers &&
          isArrayExists(schedule.teachers) &&
          schedule.teachers.find((teacher) => teacher.email === authData.email)
      );
    }
    return false;
  }, [schedules, selectedClass, authData]);

  const you = useMemo(() => {
    if (studentTrainees && selectedClass) {
      const selectedTrainee = studentTrainees.find((trainee) => trainee.schedule_id === selectedClass);
      if (selectedTrainee) {
        return selectedTrainee;
      }
    }
    return false;
  }, [studentTrainees, selectedClass]);

  useEffect(() => {
    if (selectedClass && isClassTeacher && !teacherStudentsDataLoaded) {
      getTeacherStudentsTotalRegistered(selectedClass, you.org).then(
        ({ students = [], regions = [], registrationForms = [], badgeTypes = [] }) => {
          setTeacherStudents(students.filter((student) => student.id !== you.id));
          // setTeacherStudents(students);
          setRegions(regions);
          setRegistrationForms(registrationForms);
          setBadgeTypes(badgeTypes);
          setTeacherStudentsDataLoaded(true);
        }
      );
    }
  }, [selectedClass, isClassTeacher, teacherStudentsDataLoaded, you]);

  const handleAction = (type, formData) => {
    let promises = [],
      successMessage = "";
    switch (type) {
      case "create":
        promises.push(callFunctionsAPI({ url: "student", action: "create_team", formData }));
        successMessage = "Team created successfully";
        break;
      case "create_teacher":
        promises.push(
          callFunctionsAPI({
            url: "student",
            action: "teacher_create_team",
            formData,
          })
        );
        successMessage = "Team created successfully";
        break;
      case "join":
        promises.push(callFunctionsAPI({ url: "student", action: "join_team", formData }));
        successMessage = "Team joined successfully";
        break;
      case "edit_teacher":
        promises.push(
          callFunctionsAPI({
            url: "student",
            action: "teacher_update_team",
            formData,
          })
        );
        successMessage = "Team updated successfully";
        break;
      case "delete_teacher":
        promises.push(
          callFunctionsAPI({
            url: "student",
            action: "teacher_delete_team",
            formData,
          })
        );
        successMessage = "Team deleted successfully";
        break;
      case "edit":
      default:
        promises.push(callFunctionsAPI({ url: "student", action: "update_team", formData }));
        successMessage = "Team updated successfully";
        break;
    }

    dispatch(toggleLoader(true));
    Promise.all(promises)
      .then((results) => {
        return getStudentTeams(authData.uid);
      })
      .then((data) => {
        setStudent((data && data.student) || {});
        setTeams((data && data.teams) || []);
        setSchedules((data && data.schedules) || []);
        setStudentTrainees((data && data.student_trainees) || []);
        dispatch(toggleLoader(false));
        triggerSuccessAlert(successMessage);
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
        triggerErrorAlert(getAPIErrorMessage(error));
      });
  };

  const renderScheduleSelector = () => {
    return (
      <div
        style={{
          marginBottom: "20px",
          padding: "20px",
          border: "1px solid #ddd",
          backgroundColor: "#fff",
          boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <FormSelect2
          label="Select a Class"
          value={selectedClass || ""}
          isSearchable={true}
          isClearable={true}
          placeholder="Type keyword here to search..."
          options={schedules
            .filter((s) => s.team_module && s.team_module === "yes")
            .sort((a, b) => a.class_name.localeCompare(b.class_name))
            .map((schedule) => ({
              value: schedule.id,
              label: schedule.class_name,
            }))}
          onChange={(newValue) => {
            setSelectedClass(newValue);
            setTeacherStudentsDataLoaded(false);
            setSelectedTab("teams");
          }}
        />
      </div>
    );
  };

  const handleStudentUpdate = (formData) => {
    dispatch(toggleLoader(true));
    updateStudentDataByTeacher(formData, selectedClass, you.org)
      .then(({ students = [], regions = [], registrationForms = [], badgeTypes = [] }) => {
        dispatch(toggleLoader(false));
        setTeacherStudents(students.filter((student) => student.id !== you.id));
        // setTeacherStudents(students);
        setRegions(regions);
        setRegistrationForms(registrationForms);
        setBadgeTypes(badgeTypes);
        triggerSuccessAlert("Student updated successfully");
      })
      .catch((error) => {
        dispatch(toggleLoader(false));
        triggerErrorAlert(getAPIErrorMessage(error));
      });
  };

  return (
    <AppWrapper
      title={"My Team Profile"}
      // maxWidth="1110px"
      onLoad={!loaded ? true : false}
      contents={
        loaded ? (
          <>
            {errorMsg ? (
              <ErrorMessage errorMessage={errorMsg} />
            ) : (
              <>
                {renderScheduleSelector()}
                {selectedClass !== "" ? (
                  <>
                    {isClassTeacher ? (
                      <ButtonGroup style={{ marginBottom: "20px" }}>
                        {selectedTab === "teams" ? (
                          <InfoButton onClick={() => setSelectedTab("teams")}>Teams</InfoButton>
                        ) : (
                          <GreyButton onClick={() => setSelectedTab("teams")}>Teams</GreyButton>
                        )}
                        {selectedTab === "students-total-registered" ? (
                          <InfoButton onClick={() => setSelectedTab("students-total-registered")}>Students List</InfoButton>
                        ) : (
                          <GreyButton onClick={() => setSelectedTab("students-total-registered")}>Students List</GreyButton>
                        )}
                      </ButtonGroup>
                    ) : null}
                    <div style={{ display: selectedTab === "teams" ? "block" : "none" }}>
                      <TeamsTable
                        selectedClass={selectedClass}
                        showTeamManagement={showTeamManagement}
                        view={"student"}
                        student={student}
                        student_trainees={studentTrainees}
                        teams={teams}
                        schedules={schedules}
                        teachers={combinedTeachers}
                        mentors={combinedMentors}
                        onAction={handleAction}
                      />
                    </div>
                    <div style={{ display: selectedTab === "students-total-registered" ? "block" : "none" }}>
                      {teacherStudentsDataLoaded ? (
                        <TeacherStudentsTable
                          trainees={teacherStudents}
                          schedules={schedules}
                          registrationForms={registrationForms}
                          regions={regions}
                          badgeTypes={badgeTypes}
                          onStudentUpdate={handleStudentUpdate}
                        />
                      ) : (
                        <DotsLoader />
                      )}
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : null
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    authData: state.auth && state.auth.user ? state.auth.user : null,
    changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null,
  };
};

export default compose(connect(mapStateToProps), withRouter)(StudentTeamsProfile);
