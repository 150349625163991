/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import Form from "./form";
import { isClassTeacher, isClassMentor } from "./helpers";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import TableBar from "../../components/TableBar";
import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormRadio from "../../components/FormRadio";
import FormSelect2 from "../../components/FormSelect2";
import FormDatePicker from "../../components/FormDatePicker";
import FormDateOfBirth from "../../components/FormDateOfBirth";
import FormPhoneField from "../../components/FormPhoneField";
import ButtonActions from "../../components/ButtonActions";
import ToolTipInfo from "../../components/ToolTipInfo";
import ErrorMessage from "../../components/ErrorMessage";
import DotsLoader from "../../components/DotsLoader";
import SchoolSelector from "../../modules/SchoolSelector";

import {
  InfoButton,
  SuccessButton,
  GreyButton,
  ButtonGroup,
  InfoLink,
  SuccessLink,
  InverseButton,
  ErrorButton,
  AInverseLink,
} from "../../styles/button";
import { GreyTag, SuccessTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue } from "../../helpers/data";
// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { getStateValue } from "../../helpers/registration_form";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";
import { getStudentEnrollmentStatus } from "../../helpers/trainees";
import {
  getCustomFields,
  isDefaultField,
  isUniqueField,
  getFieldType,
  getCustomFieldValue,
  getCustomFieldOptions,
  getCustomField,
} from "../../helpers/registration_form";

import { traineeSchema } from "../../schemas/trainee";

import theme from "../../theme";

import { GENDER_OPTIONS, PLATFORM_BACKPACK_URL } from "../../constants";

const StudentForm = ({ student = {}, schedules = [], regions = [], badgeTypes = [], registrationForms = [], onFormUpdate = () => {} }) => {
  const schedule = useMemo(() => {
    return schedules && isArrayExists(schedules) ? _find(schedules, { id: student.schedule_id }) : false;
  }, [schedules, student]);

  const fields = useMemo(() => {
    return schedule && registrationForms && isArrayExists(registrationForms) ? getCustomFields(schedule, registrationForms, "all") : false;
  }, [schedule, registrationForms]);

  const schoolField = useMemo(() => {
    return fields && getCustomField(fields, "org");
  }, [fields]);

  const stateField = useMemo(() => {
    return fields && getCustomField(fields, "state");
  }, [fields]);

  const handleFormUpdate = (newValue, key) => {
    const newData = {
      ...student,
      [key]: newValue,
    };
    onFormUpdate(newData);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelect
            label="Partner Name *"
            name="region"
            value={student.region || ""}
            options={getSelectOptions({
              list: regions || false,
              options: [],
              keys: { value: "id", label: "label", disabled: "status" },
              sortBy: "label",
            })}
            disabled={true}
            onChange={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Name *" name="name" value={student.name || ""} onChange={handleFormUpdate} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput
            label="Email *"
            name="email"
            type="email"
            disabled={
              getStudentEnrollmentStatus(student) === "registered" || getStudentEnrollmentStatus(student) === "trial" ? false : true
            }
            value={student.email || ""}
            onChange={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelect label="Gender *" name="gender" value={student.gender || ""} options={GENDER_OPTIONS} onChange={handleFormUpdate} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDateOfBirth
            label="Date of Birth *"
            name="date_of_birth_string"
            value={student.date_of_birth_string || ""}
            onChange={handleFormUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {fields && schoolField ? (
            <SchoolSelector
              predefinedState={stateField ? getCustomFieldValue(stateField, student) : null}
              value={student.org || ""}
              field={schoolField}
              onChange={handleFormUpdate}
            />
          ) : (
            <FormInput label="School" name="org" value={student.org || ""} onChange={handleFormUpdate} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Parent Name" name="parent_name" value={student.parent_name || ""} onChange={handleFormUpdate} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={10}>
              <FormInput
                label="Parent Email"
                name="parent_email"
                type="email"
                value={student.parent_email || ""}
                onChange={handleFormUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={2} align="right">
              <AInverseLink
                target="_blank"
                href={`mailto:${student.parent_email || ""}`}
                style={{ padding: "10px 0px 10px 5px", marginTop: "10px" }}
              >
                <i className="fa fa-envelope" style={{ fontSize: "30px" }}></i>
              </AInverseLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} sm={10}>
              <FormPhoneField
                label="Parent Mobile No."
                name="parent_phone"
                value={student.parent_phone || ""}
                onChange={handleFormUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={2} align="right">
              <AInverseLink
                target="_blank"
                href={`https://api.whatsapp.com/send/?phone=${
                  student.parent_phone && !_isEmpty(student.parent_phone) ? student.parent_phone.replace("+", "") : ""
                }`}
                style={{ padding: "10px 0px 10px 5px", marginTop: "15px" }}
              >
                <i className="fa fa-whatsapp" style={{ fontSize: "30px" }}></i>
              </AInverseLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInput label="Parent City" name="parent_city" value={student.parent_city || ""} onChange={handleFormUpdate} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormInput
            multiline={true}
            rows={3}
            label="Registration Remarks"
            name="remarks"
            value={student.remarks || ""}
            onChange={handleFormUpdate}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ModalStudent = ({
  student = {},
  schedules = [],
  regions = [],
  registrationForms = [],
  badgeTypes = [],
  open = false,
  onClose = () => {},
  onStudentUpdate = () => {},
  onFormUpdate = () => {},
}) => {
  const handleStudentUpdate = () => {
    onStudentUpdate(student);
  };

  return (
    <ModalView
      open={open}
      title="Edit Student Details"
      maxWidth="lg"
      onClose={onClose}
      disableBackdrop={true}
      actionLabel="Update"
      doAction={handleStudentUpdate}
      contents={
        <>
          <StudentForm
            student={student}
            schedules={schedules}
            regions={regions}
            badgeTypes={badgeTypes}
            registrationForms={registrationForms}
            onFormUpdate={onFormUpdate}
          />
        </>
      }
    />
  );
};

export default ModalStudent;
