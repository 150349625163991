/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import shortid from "shortid";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _replace from "lodash/replace";
import _remove from "lodash/remove";

import ModalDelete from "../../components/ModalDelete";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormPhoneField from "../../components/FormPhoneField";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { getSelectedValue, cloneCollections } from "../../helpers/data";
import { getMomentTime, printSemiPrettyDate } from "../../helpers/date";
import { isSkip, isSchemaRequired } from "../../helpers/schemas";

import { Button, InverseButton, InverseLink } from "../../styles/button";
import { ErrorBox } from "../../styles/message";

// import { traineeSchema } from "../../schemas/trainee";

import { DAY_OPTIONS, GENDER_OPTIONS } from "../../constants";

const FormWrapper = styled.div`
  padding: 0px;
`;

const useStyles = (theme) => ({
  boxheading: {
    fontSize: "20px",
    fontWeight: "700",
    color: theme.palette.background,
    paddingBottom: "15px",
    marginBottom: "15px",
    borderBottom: "1px solid #ddd",
  },
});

const Team = ({ team = {}, onChange = () => {} }) => {
  return <div></div>;
};

class TeamForm extends React.Component {
  state = {};

  handleFormChange = (teamId) => (newValue, key) => {
    const { teams = [] } = this.props;
    let newTeams = teams && isArrayExists(teams) ? [...teams] : [];
    const teamIndex = _findIndex(teams, (t) => t.id === teamId);
    if (teamIndex !== -1) {
      newTeams[teamIndex][key] = newValue;
    } else {
      let newTeam = {
        id: shortid.generate(),
        name: "",
        teacher: "",
        mentor: "",
        state: "",
        trainees: [],
      };
      newTeam[key] = newValue;
      newTeams.push(newTeam);
    }

    this.props.onUpdate(newTeams);
  };

  getTheSelectedTeam = () => {
    const { teams = [] } = this.props;
    return teams && isArrayExists(teams) && teams.length > 0
      ? teams[0]
      : {
          id: shortid.generate(),
          name: "",
          teacher: "",
          mentor: "",
          state: "",
          trainees: [],
        };
  };

  getTeachersOptions = () => {
    const { schedule } = this.props;
    return schedule.teachers_mentors && isArrayExists(schedule.teachers_mentors)
      ? schedule.teachers_mentors
          .filter((t) => t.role === "teacher")
          .map((teacher) => ({
            value: teacher.email,
            label: teacher.name + (teacher.school && !_isEmpty(teacher.school) ? " (" + teacher.school + ")" : ""),
          }))
      : [];
  };

  getMentorsOptions = () => {
    const { schedule } = this.props;
    return schedule.teachers_mentors && isArrayExists(schedule.teachers_mentors)
      ? schedule.teachers_mentors
          .filter((t) => t.role === "mentor")
          .map((mentor) => ({
            value: mentor.email,
            label: mentor.name + (mentor.school && !_isEmpty(mentor.school) ? " (" + mentor.school + ")" : ""),
          }))
      : [];
  };

  isTraineesValid = () => {
    const { trainees = [] } = this.props;
    let valid = false;
    if (trainees && isArrayExists(trainees) && trainees.length > 0) {
      trainees.forEach((trainee) => {
        if (trainee.name && !_isEmpty(trainee.name) && trainee.email && !_isEmpty(trainee.email) && trainee.org && !_isEmpty(trainee.org)) {
          valid = true;
        }
      });
    }
    return valid;
  };

  isTeamSizeExceeded = () => {
    const { trainees = [], schedule = {} } = this.props;
    return trainees && isArrayExists(trainees) && trainees.length > schedule.team_size;
  };

  render = () => {
    const { trainees, schedule } = this.props;
    const selectedTeam = this.getTheSelectedTeam();
    return (
      <FormWrapper>
        <Grid container spacing={2}>
          {this.isTeamSizeExceeded() ? (
            <Grid item xs={12}>
              <ErrorBox>
                You have exceeded the maximum team size of {schedule.team_size}. Please remove the participant registration above. If you
                want to register more participants, please refill the form again after submitting the form.
              </ErrorBox>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <FormInput label="Team Name" value={selectedTeam.name || ""} name="name" onChange={this.handleFormChange(selectedTeam.id)} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormSelect2
              label="Teacher"
              value={selectedTeam.teacher || ""}
              name="teacher"
              onChange={this.handleFormChange(selectedTeam.id)}
              isSearchable={true}
              options={this.getTeachersOptions()}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormSelect2
              label="Mentor"
              value={selectedTeam.mentor || ""}
              name="mentor"
              onChange={this.handleFormChange(selectedTeam.id)}
              isSearchable={true}
              options={this.getMentorsOptions()}
            />
          </Grid>
          {this.isTraineesValid() ? (
            <Grid item xs={12}>
              <div style={{ padding: "10px" }}>
                <Typography variant="h5" style={{ color: "#999", fontWeight: "700", marginBottom: "15px" }}>
                  Team Member(s):
                </Typography>
                {trainees.map((trainee) => (
                  <div key={trainee.email} style={{ marginBottom: "10px" }}>
                    <i className="fa fa-user" style={{ marginRight: "5px" }}></i> {trainee.name}
                  </div>
                ))}
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Typography variant="body1" style={{ color: "#212121", fontWeight: "700" }}>
              * Please note that all the participants registered above will be added to the team. If you need to register another team,
              please refill the form again after submitting the form.
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="body1" style={{ color: "#D32F2F", fontWeight: "700" }}>
              * Compulsory to fill in
            </Typography>
          </Grid> */}
        </Grid>
      </FormWrapper>
    );
  };
}

export default compose(withStyles(useStyles), withRouter)(TeamForm);
